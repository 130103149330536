export const getCookie = (name: string): string => {
// ssr friendly
  if (typeof document !== 'undefined' && typeof document.cookie !== 'undefined') {
    const decoded = decodeURIComponent(document.cookie); // to be careful
    const arr = decoded.split(';').map(item => item.trim());
    const cookie = arr.find(val => val.indexOf(`${name}=`) === 0) ?? '';
    return cookie.length > name.length + 1 ? cookie.substring(name.length + 1) : '';
  }
  return '';
}

export const setCookie = (name: string, value: string, expiresIn?: number) => {
  if (typeof document !== 'undefined' && typeof document.cookie !== 'undefined') {
    if (typeof expiresIn !== 'undefined') {
      const expiration = new Date();
      expiration.setSeconds(expiration.getSeconds() + expiresIn);
      value += `;expires=${expiration.toUTCString()}`
    }
    value += `;domain=${process.env.REACT_APP_JWT_COOKIE_DOMAIN};path=/`
    document.cookie = `${name}=${value}`;
  }
};

export const deleteCookie = (name: string) => {
  if (typeof document !== 'undefined' && typeof document.cookie !== 'undefined') {
    const expiration = new Date();
    expiration.setTime(expiration.getTime() + (-1 * 24 * 60 * 60 * 1000));

    document.cookie = `${name}=;expires=${expiration.toUTCString()};domain=${process.env.REACT_APP_JWT_COOKIE_DOMAIN};path=/`;
  }
};
