import React, { FC } from 'react';
import { Link, Navigate, Outlet } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import routes from '../../config/routes';
import { getIdToken, isAuthenticated } from '../../utils/authentication';
import ErrorInterceptor from '../../components/ErrorHandlers/ErrorInterceptor';
import { tradCorporateLayout } from './CorporateLayoutKeyTranslation';

const CorporateLayout: FC = () => {
  const { tradCorporateLayoutLogout } = tradCorporateLayout();
  const encodedLogoutCallbackUri = encodeURIComponent(process.env.REACT_APP_BASE_URL + '/logout-callback');
  const logoutUri = `${process.env.REACT_APP_IDENTITY_URL}/connect/endsession?id_token_hint=${getIdToken()}&post_logout_redirect_uri=${encodedLogoutCallbackUri}`;
  const linkIsLogged = isAuthenticated()
    ? <Link
      className="btn btn-link"
      to={logoutUri} >
      {tradCorporateLayoutLogout}
    </Link>
    : <Navigate to="/" />

  const footerProps = {
    className: 'mb-3',
    text: 'Stereograph',
    class: 'mb-3 text-end',
    version: `Version ${process.env.REACT_APP_VERSION}`
  }

  return (
    <Layout footerProps={footerProps} pageHolderClass="bg-gray-100 justify-content-center"
      routes={routes} link={linkIsLogged}>
      <Outlet />
      <ErrorInterceptor />
    </Layout>
  )
}

export default CorporateLayout;
