import axios from '../middleware/requestInterceptor';
import { Delegation } from '../interface/delegation';

export const delegationAPi = {
    getAll: async (instanceId: number) => {
        const result = await axios.get(`/instances/${instanceId}/delegations`);
        return result;
    },
    create: async (instanceId: number, delegation: Delegation) => {
        const result = await axios.post(`/instances/${instanceId}/delegations`, delegation);
        return result;
    },
    update: async (instanceId: number, delegation: Delegation) => {
        const result = await axios.put(`/instances/${instanceId}/delegations/${delegation.id}`, delegation);
        return result;
    },
    delete: async (instanceId: number, delegationId: number) => {
        const result = await axios.delete(`/instances/${instanceId}/delegations/${delegationId}`);
        return result;
    }
}
