import React, { FC, useContext } from 'react';
import { accountApi } from '../../../api/accountApi';
import { ToasterContext } from '../../../pages/App/App';
import { CustomModal } from '@stereograph/storybook/dist/components/CustomModal/CustomModal';
import { Account } from '../../../interface/account';
import { tradDeleteAccount, tradToasterAccount } from './AccountKeyTranslation';

interface AccountFormProps {
  show: boolean;
  setShow: Function;
  account: Account;
  refreshData: boolean;
  setRefreshData: Function;
}

const DeleteAccountModal: FC<AccountFormProps> = (props) => {
  const { tradToasterAccountSuccess, tradToasterAccountSuccessDelete } = tradToasterAccount();
  const { tradDeleteAccountCancel, tradDeleteAccountTitle, tradDeleteAccountConfirmation, tradDeleteAccountMessage } = tradDeleteAccount();

  const { pushToast } = useContext(ToasterContext);
  const { show, setShow, account, refreshData, setRefreshData } = props;

  const deleteAccount = async () => {
    const result = await accountApi.delete(account);
    if (result?.status === 204) {
      pushToast({
        type: 'success',
        title: tradToasterAccountSuccess,
        message: tradToasterAccountSuccessDelete,
        autoHide: true
      });
      setRefreshData(!refreshData);
    }
    setShow(false);
  }
  return (
    <CustomModal show={show} setShow={setShow}
      onAction={deleteAccount} cancelButton={tradDeleteAccountCancel}
      actionName={tradDeleteAccountConfirmation} type="danger" title={tradDeleteAccountTitle}>
      {tradDeleteAccountMessage}
    </CustomModal>
  )
}

export default DeleteAccountModal;
