/* eslint-disable no-unused-vars */
import { t } from 'i18next';
import { translatedText } from '../../../i18n/config';

export function tradToaster() {
    const tradToasterSuccess = translatedText('messages.success', t);
    const tradToasterDelegationCreation = translatedText('delegation.create.success', t);
    const tradToasterDelegationUpdate = translatedText('delegation.update.success', t);
    const tradToasterDelegationDelete = translatedText('delegation.delete.success', t);
    return { tradToasterSuccess, tradToasterDelegationCreation, tradToasterDelegationUpdate, tradToasterDelegationDelete };
}

export function tradEdition() {
    const tradEditionAddDelegation = translatedText('delegation.add_delegation', t);
    const tradEditionUpdateDelegation = translatedText('delegation.update_delegation', t);
    const tradEditionAdd = translatedText('delegation.add', t);
    const tradEditionCancel = translatedText('cancel', t);
    return { tradEditionAddDelegation, tradEditionUpdateDelegation, tradEditionAdd, tradEditionCancel };
}

export function tradDelete() {
    const tradDeleteCancel = translatedText('cancel', t);
    const tradDeleteAction = translatedText('delegation.delete.confirm', t);
    const tradDeleteTitle = translatedText('Confirmation', t);
    const tradinstanceDeleteMessage = translatedText('delegation.delete.confirmation_message', t);
    return { tradDeleteCancel, tradDeleteAction, tradDeleteTitle, tradinstanceDeleteMessage };
}
