import React, { FC, useState, useEffect } from 'react'
import { Form } from 'react-bootstrap';
import { Delegation } from '../../interface/delegation';
import { Link } from 'react-router-dom';
import slugify from 'slugify';
import { tradDelegationForm, tradDelegationKeys, tradDelegationShowMore } from './DelegationFormKeyTranslation';

interface DelegationFormProps {
    delegation: Delegation,
    onNameChanged: Function,
    onDiscoveryEndpointChanged: Function,
    onClientIdChanged: Function,
    onClientSecretChanged: Function,
    onAuthorityChanged: Function,
    onAuthorizationEndpointChanged: Function,
    onTokenEndpointChanged: Function,
    onUserInfoChanged: Function,
    onChageDelegationType: Function,
    onChangeDelegationAutoRedirect: Function,
    onChangeDelegationAutoConfirmEmail: Function,
    onChangeDelegationForceResetPassword: Function,
    isEdition: boolean
}
const DelegationForm: FC<DelegationFormProps> = (props) => {
    const { delegation, onNameChanged, onDiscoveryEndpointChanged, onAuthorityChanged, onAuthorizationEndpointChanged, onChageDelegationType, onClientIdChanged, onClientSecretChanged, onTokenEndpointChanged, onUserInfoChanged, onChangeDelegationAutoRedirect, onChangeDelegationAutoConfirmEmail, onChangeDelegationForceResetPassword, isEdition } = props;
    const { tradDelegationFormName, tradDelegationFormId, tradDelegationFormSecret, tradDelegationFormEndPoint, tradDelegationFormStartegy, tradDelegationFormAutoRedirect, tradDelegationFormAutoConfirmEmail, tradDelegationFormResetPassword, tradDelegationFormScheme, tradDelegationFormShowMore, tradDelegationFormPlaceHolder } = tradDelegationForm();
    const { tradDelegationShowMoreAuthority, tradDelegationShowMoreAuthorization, tradDelegationShowMoreToken, tradDelegationShowMoreUserInfo, tradDelegationShowMoreShowLess } = tradDelegationShowMore();
    const { tradDelegationKeysDefault, tradDelegationKeysMicrosoft, tradDelegationCognito, tradDelegationKeysGoogle } = tradDelegationKeys();
    const [showMore, setShowMore] = useState<Boolean>(false);
    const [name, setName] = useState(delegation.name);
    const [slug, setSlug] = useState('');

    useEffect(() => {
        let resultat = '';
        if (isEdition) {
            resultat = `${delegation.scheme.split('-')[0]}-`;
        } else {
            resultat = `${delegation.instanceToken}-`
        }
        const newSlug = resultat + slugify(name, { lower: true });
        setSlug(newSlug);
    }, [name]);

    const onSlugify = (event) => {
        setName(event.target.value);
    }

    return (
        <Form>
            <Form.Group className='mb-3'>
                <Form.Label>{tradDelegationFormName}</Form.Label>
                <Form.Control onChange={(event) => { onNameChanged(event); onSlugify(event) }} type="text" placeholder={tradDelegationFormPlaceHolder} value={name}></Form.Control>
            </Form.Group>
            <Form.Group className='mb-3'>
                <Form.Label>{tradDelegationFormId}</Form.Label>
                <Form.Control onChange={(event) => { onClientIdChanged(event) }} type="text" placeholder="ClientId" value={delegation.clientId}></Form.Control>
            </Form.Group>
            <Form.Group className='mb-3'>
                <Form.Label>{tradDelegationFormSecret}</Form.Label>
                <Form.Control onChange={(event) => { onClientSecretChanged(event) }} type="text" placeholder="ClientSecret" value={delegation.clientSecret}></Form.Control>
            </Form.Group>
            <Form.Group className='mb-3'>
                <Form.Label>{tradDelegationFormEndPoint}</Form.Label>
                <Form.Control onChange={(event) => { onDiscoveryEndpointChanged(event) }} type="text" placeholder="DiscoveryEndpoint" value={delegation.discoveryEndpoint}></Form.Control>
            </Form.Group>
            <Form.Group className='mb-3'>
                <Form.Label>{tradDelegationFormStartegy}</Form.Label>
                <Form.Select onChange={(event) => onChageDelegationType(event)} defaultValue={delegation.roleStrategy}>
                    <option value={0}>{tradDelegationKeysDefault}</option>
                    <option value={1}>{tradDelegationKeysMicrosoft}</option>
                    <option value={2}>{tradDelegationCognito}</option>
                    <option value={3}>{tradDelegationKeysGoogle}</option>
                </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3 d-flex">
                <Form.Label className='me-2'>{tradDelegationFormAutoRedirect}</Form.Label>
                <Form.Check defaultChecked={delegation.configuration.autoRedirect} onChange={(event) => onChangeDelegationAutoRedirect(event)}></Form.Check>
            </Form.Group>
            <Form.Group className="mb-3 d-flex">
                <Form.Label className='me-2'>{tradDelegationFormAutoConfirmEmail}</Form.Label>
                <Form.Check defaultChecked={delegation.configuration.autoConfirmEmail} onChange={(event) => onChangeDelegationAutoConfirmEmail(event)}></Form.Check>
            </Form.Group>
            <Form.Group className="mb-3 d-flex">
                <Form.Label className='me-2'>{tradDelegationFormResetPassword}</Form.Label>
                <Form.Check defaultChecked={delegation.configuration.forceResetPassword} onChange={(event) => onChangeDelegationForceResetPassword(event)}></Form.Check>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label className="signin-redirect">{tradDelegationFormScheme}</Form.Label>
                <Form.Label className="signin-redirect">{`${process.env.REACT_APP_IDENTITY_URL}/singin-${slug}`}</Form.Label>
            </Form.Group>
            {!showMore && <div className='mt-2'><Link to='#' onClick={() => setShowMore(true)}>{tradDelegationFormShowMore}</Link></div>}
            {
                showMore && <>
                    <Form.Group className='mb-3'>
                        <Form.Label>{tradDelegationShowMoreAuthority}</Form.Label>
                        <Form.Control onChange={(event) => { onAuthorityChanged(event) }} type="text" placeholder="Authority" value={delegation.authority}></Form.Control>
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label>{tradDelegationShowMoreAuthorization}</Form.Label>
                        <Form.Control onChange={(event) => { onAuthorizationEndpointChanged(event) }} type="text" placeholder="AuthorizationEndpoint" value={delegation.authorizationEndpoint}></Form.Control>
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label>{tradDelegationShowMoreToken}</Form.Label>
                        <Form.Control onChange={(event) => { onTokenEndpointChanged(event) }} type="text" placeholder="TokenEndpoint" value={delegation.tokenEndpoint}></Form.Control>
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label>{tradDelegationShowMoreUserInfo}</Form.Label>
                        <Form.Control onChange={(event) => { onUserInfoChanged(event) }} type="text" placeholder="UserInfoEndpoint" value={delegation.userInfoEndpoint}></Form.Control>
                    </Form.Group>
                    <div className='mt-2'><Link to='#' onClick={() => setShowMore(false)}>{tradDelegationShowMoreShowLess}</Link></div>
                </>
            }
        </Form>
    )
}
export default DelegationForm;
