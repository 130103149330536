import { t } from 'i18next';
import { Instance } from '../models/instance';
import axios from '../middleware/requestInterceptor';
import { stereoApplicationApi } from './stereoApplicationApi';

export const instanceApi = {
  getAll: async () => {
      const result = await axios.get('/instances');
      const arrayInstance:Array<Instance> = [];
          result.data.map((instance:Instance) => {
            const date:Date = new Date(instance.createdAt);
            return arrayInstance.push({
              ...instance, createdLocaleDate: date.toLocaleString(t('date.format'))
            })
          })
        return arrayInstance;
  },
  getAllByAccount: async (accountId) => {
      const result = await axios.get(`/instances/${accountId}/instances`);
      return result.data;
  },
  getInstanceById: async (instanceId) => {
      const result = await axios.get(`/instances/${instanceId}`);
      return result.data;
  },
  delete: async (instanceId: number) => {
      const result = await axios.delete(`/instances/${instanceId}`, { data: { instanceId: instanceId } });
      return result;
    },
  getUsers: async () => {
      const result = await axios.get('/instances/users');
      const arrayUser = result.data.map((user) => {
          const date = new Date(user.createdAt);
          return {
              ...user,
              createdLocaleDate: date.toLocaleString(t('date.format'))
          };
      });
      return arrayUser;
  },
  create: async (instance) => {
      const result = await axios.post('/instances', {
        accountId: instance.instanceAccountNameValue,
        name: instance.instanceValue,
        type: instance.hostingValueType,
        state: instance.instanceStatusValue,
        environment: instance.environment
      })
      let output;
      if (result?.status === 200 && (instance?.teiaCoreUrlValue !== '' || instance?.digitalTwinUrlValue !== '' || instance?.teiaEngineUrlValue !== '' || instance?.teiaConnectorUrlValue !== '' || instance?.teiaWorkflowUrlValue !== '')) {
        if (instance.teiaCoreUrlValue !== '') {
          const instanceAdded = result.data;
          output = await stereoApplicationApi.create({
            instanceId: instanceAdded.instanceId,
            url: instance.teiaCoreUrlValue,
            type: 1
          });
          if (output?.status !== 200) {
            return output;
          }
        }

        if (instance?.digitalTwinUrlValue !== '') {
          const instanceAdded = result.data;
          output = await stereoApplicationApi.create({
            instanceId: instanceAdded.instanceId,
            url: instance.digitalTwinUrlValue,
            type: 2
          });
          if (output?.status !== 200) {
            return output;
          }
        }

        if (instance.teiaEngineUrlValue !== '') {
          const instanceAdded = result.data;
          output = await stereoApplicationApi.create({
            instanceId: instanceAdded.instanceId,
            url: instance.teiaEngineUrlValue,
            type: 3
          });
          if (output?.status !== 200) {
            return output;
          }
        }

        if (instance.teiaConnectorUrlValue !== '') {
          const instanceAdded = result.data;
          output = await stereoApplicationApi.create({
            instanceId: instanceAdded.instanceId,
            url: instance.teiaConnectorUrlValue,
            type: 4
          });
          if (output?.status !== 200) {
            return output;
          }
        }

        if (instance.teiaWorkflowUrlValue !== '') {
          const instanceAdded = result.data;
          output = await stereoApplicationApi.create({
            instanceId: instanceAdded.instanceId,
            url: instance.teiaWorkflowUrlValue,
            type: 5
          });
          if (output?.status !== 200) {
            return output;
          }
        }
      }
      return result;
  },
  update: async (instance: Instance) => {
      const result = await axios.put(`/instances/${instance.instanceId}`,
        {
          accountId: instance.accountId,
          name: instance.name,
          type: instance.type,
          state: instance.state,
          comment: instance.comment,
          instanceId: instance.instanceId,
          environment: instance.environment
        })
      return result;
  },
  exportInstances: async () => {
    try {
      const response = await axios.get('/instances/export', { responseType: 'blob' });
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Corporate_List_Instances.xlsx';
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting all instances: ', error);
    }
  }
}
