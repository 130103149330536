import React, { FC, useState } from 'react';
import { Form, ButtonGroup, Button } from 'react-bootstrap'
import { License } from '../../interface/License/License';
import { Account } from '../../interface/account';
import { Instance } from '../../models/instance';
import Select from 'react-select';
import { tradLicenseForm } from './DelegationFormKeyTranslation';

interface LicenseFormProps {
  originalInstance?: Instance | null,
  license: License,
  accounts: Array<Account>,
  instances: Array<Instance>,
  onAccountChanged: Function,
  onInstanceChanged: Function,
  onLicenseTypeChanged: Function,
  onLimitDateChanged: Function,
  onLimitUsersChanged: Function,
  onLimitProjectsChanged: Function
}
const LicenseForm: FC<LicenseFormProps> = (props) => {
  const { accounts, instances, originalInstance, onAccountChanged, onInstanceChanged, onLicenseTypeChanged, onLimitDateChanged, onLimitProjectsChanged, onLimitUsersChanged } = props;
  const [activeLicenseType, setActiveLicenseType] = useState<number>(0);
  const { tradLicenseFormAccount, tradLicenseFormInstances, tradLicenseFormType, tradLicenseFormDate, tradLicenseFormUsers, tradLicenseFormProjects } = tradLicenseForm();
  const isOnInstanceSelected: boolean = originalInstance !== undefined || originalInstance != null;
  const accountOption = isOnInstanceSelected ? [{ label: originalInstance?.accountName, value: originalInstance?.accountId }] : accounts.map((account: Account) => ({ label: account.name, value: account.id }))
  const instanceOption = isOnInstanceSelected ? [{ label: originalInstance?.name, value: originalInstance?.instanceId }] : instances.map((instance: Instance) => ({ label: instance.name, value: instance.instanceId }))
  return (
    <>
      <Form>
        <Form.Group>
          <Form.Label className='mt-2'> {tradLicenseFormAccount}</Form.Label>
          <Select
            options={accountOption}
            onChange={(newValue) => onAccountChanged(newValue)}
            placeholder={!isOnInstanceSelected && tradLicenseFormAccount}
            isDisabled={isOnInstanceSelected}
            defaultValue={isOnInstanceSelected ? accountOption : null} />
        </Form.Group>
        <Form.Group>
          <Form.Label className='mt-2'> {tradLicenseFormInstances}</Form.Label>
          <Select
            options={instanceOption}
            onChange={(newValue) => onInstanceChanged(newValue)}
            placeholder={!isOnInstanceSelected && tradLicenseFormInstances}
            isDisabled={isOnInstanceSelected}
            defaultValue={isOnInstanceSelected ? instanceOption : null} />
        </Form.Group>
        <Form.Group>
          <Form.Label className='d-block mt-2'> {tradLicenseFormType}</Form.Label>
          <ButtonGroup onClick={(event) => onLicenseTypeChanged(event)}>
            <Button className={activeLicenseType === 1 ? 'active bg-dark' : ''} onClick={() => setActiveLicenseType(1)} variant="secondary" value='1'>Prod</Button>
            <Button className={activeLicenseType === 2 ? 'active bg-dark' : ''} onClick={() => setActiveLicenseType(2)} variant="secondary" value='2'>Preprod</Button>
            <Button className={activeLicenseType === 3 ? 'active bg-dark' : ''} onClick={() => setActiveLicenseType(3)} variant="secondary" value='3'>SDK</Button>
            <Button className={activeLicenseType === 4 ? 'active bg-dark' : ''} onClick={() => setActiveLicenseType(4)} variant="secondary" value='4'>Démo</Button>
          </ButtonGroup>
        </Form.Group>
        <Form.Group>
          <Form.Label className='mt-2'> {tradLicenseFormDate}</Form.Label>
          <Form.Control type='date' onChange={(event) => onLimitDateChanged(event)} name="licenseDate"></Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label className='mt-2'> {tradLicenseFormUsers}</Form.Label>
          <Form.Control onChange={(event) => onLimitUsersChanged(event)} name="limitUsers" type="number" min="1" ></Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label className='mt-2'> {tradLicenseFormProjects}</Form.Label>
          <Form.Control onChange={(event) => onLimitProjectsChanged(event)} name="limitProjects" type="number" min="1" ></Form.Control>
        </Form.Group>
      </Form>
    </>
  )
}
export default LicenseForm;
