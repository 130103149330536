import React, { FC, useContext } from 'react';
import { ToasterContext } from '../../../pages/App/App';
import { CustomModal } from '@stereograph/storybook/dist/components/CustomModal/CustomModal';
import { instanceApi } from '../../../api/instanceApi';
import { Instance } from '../../../models/instance';
import { tradToaster, tradinstanceDelete } from './InstanceKeyTranslation';

interface FormProps {
    show: boolean;
    setShow: Function;
    currentInstance: Instance;
    refreshInstance: Function;
}

const DeleteInstanceModal: FC<FormProps> = (props) => {
    const { tradToasterSuccess, tradToasterDeleteSuccess } = tradToaster();
    const { tradinstanceDeleteCancel, tradinstanceDeleteConfirm, tradinstanceDeleteTitle, tradinstanceDeleteMessage } = tradinstanceDelete();
    const { pushToast } = useContext(ToasterContext);
    const { show, setShow, currentInstance, refreshInstance } = props;

    const deleteInstance = async () => {
        const deleteInstance = await instanceApi.delete(currentInstance.instanceId);
        if (deleteInstance?.status === 204) {
            pushToast({
                type: 'success',
                title: tradToasterSuccess,
                message: tradToasterDeleteSuccess,
                autoHide: true
            })
            refreshInstance();
        }
        setShow(false)
    }
    return (
        <CustomModal
            show={show}
            setShow={setShow}
            onAction={deleteInstance}
            cancelButton={tradinstanceDeleteCancel}
            actionName={tradinstanceDeleteConfirm}
            type="danger"
            title={tradinstanceDeleteTitle} >
            {tradinstanceDeleteMessage}
        </CustomModal>
    )
}

export default DeleteInstanceModal;
