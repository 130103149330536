import React, { FC, useState, useContext, useEffect } from 'react';
import { CustomModal } from '@stereograph/storybook/dist/components/CustomModal/CustomModal';
import { License } from '../../../interface/License/License';
import { instanceApi } from '../../../api/instanceApi';
import { ToasterContext } from '../../../pages/App/App';
import LicenseForm from '../../Form/LicenseForm';
import { licenseApi } from '../../../api/licenseApi';
import { Account } from '../../../interface/account';
import { Instance } from '../../../models/instance';
import { tradCreation, tradToaster } from './LicenseKeyTranslation';

interface LicenseFormProps {
    show: boolean;
    setShow: Function;
    refreshData: boolean;
    setRefreshData: Function;
    accounts: Array<Account>;
    originalInstance?: Instance | null
}

const CreateLicenseModal: FC<LicenseFormProps> = (props) => {
    const { tradToasterSuccess, tradToasterSuccessMessage } = tradToaster();
    const { tradCreationValidation, tradCreationTitle, tradCreationCancel } = tradCreation();
    const { show, accounts, setShow, refreshData, setRefreshData, originalInstance } = props;
    const { pushToast } = useContext(ToasterContext);
    const [instances, setInstances] = useState<Array<any>>([]);
    const [currentAccountId] = useState<number>(0);
    const [license, setLicense] = useState<License>({
        id: 0,
        instanceId: 0,
        expirationDate: '',
        usersCount: 0,
        projectsCount: 0,
        type: 1
    });

    useEffect(() => {
        const fetchInstances = async () => { }
        fetchInstances();
    }, [currentAccountId]);

    const onAccountChanged = async (newValue) => {
        const accountId = newValue.value;
        const result = await instanceApi.getAllByAccount(accountId);
        setInstances(result);
    }

    const onInstanceChanged = (newValue) => {
        const newLicense = Object.assign({}, license);
        newLicense.instanceId = newValue.value;
        setLicense(newLicense);
    }

    const onLicenseTypeChanged = (event) => {
        const newLicense = Object.assign({}, license);
        newLicense.type = event.target.value;
        setLicense(newLicense);
    }

    const onLimitDateChanged = (event) => {
        const newLicense = Object.assign({}, license);
        newLicense.expirationDate = event.target.value;
        setLicense(newLicense);
    }

    const onLimitUsersChanged = (event) => {
        const newLicense = Object.assign({}, license);
        newLicense.usersCount = event.target.value;
        setLicense(newLicense);
    }

    const onLimitProjectsChanged = (event) => {
        const newLicense = Object.assign({}, license);
        newLicense.projectsCount = event.target.value;
        setLicense(newLicense);
    }

    const onSubmiteForm = async (license) => {
        if (originalInstance !== undefined) {
            license.instanceId = originalInstance?.instanceId;
        }
        const result = await licenseApi.create(license);
        if (result?.status === 200) {
            pushToast({
                type: 'success',
                title: tradToasterSuccess,
                message: tradToasterSuccessMessage,
                autoHide: true
            });
            setRefreshData(!refreshData);
        }
        setShow(false);
    }

    return (
        <CustomModal show={show} setShow={setShow}
            onAction={() => { onSubmiteForm(license) }} actionName={tradCreationValidation}
            title={tradCreationTitle} type="primary" cancelButton={tradCreationCancel}>
            <LicenseForm
                originalInstance={originalInstance}
                license={license}
                accounts={accounts}
                instances={instances}
                onAccountChanged={onAccountChanged}
                onInstanceChanged={onInstanceChanged}
                onLicenseTypeChanged={(event) => { onLicenseTypeChanged(event) }}
                onLimitDateChanged={(event) => { onLimitDateChanged(event) }}
                onLimitUsersChanged={(event) => { onLimitUsersChanged(event) }}
                onLimitProjectsChanged={(event) => { onLimitProjectsChanged(event) }}
            />
        </CustomModal>
    )
}
export default CreateLicenseModal;
