/* eslint-disable no-unused-vars */
import { t } from 'i18next';
import { translatedText } from '../../i18n/config';

export function tradError403() {
    const tradError403Title = translatedText('page_non_authorize.title', t);
    const tradError403Description = translatedText('page_non_authorize.description', t);
    const tradError403Logout = translatedText('logout', t);
    const tradHomeImageDesc = translatedText('home.description', t);
    return { tradError403Title, tradError403Description, tradError403Logout, tradHomeImageDesc };
}

export function tradError404() {
    const tradError404Title = translatedText('page_not_found.title', t);
    const tradError404Description = translatedText('page_not_found.description', t);
    const tradHomeImageDesc = translatedText('home.description', t);
    return { tradError404Title, tradError404Description, tradHomeImageDesc };
}
