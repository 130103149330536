/* eslint-disable camelcase */
import { Guid } from '../../models/guid';

export type ClientIdentityProperty = TClientIdentityProperties | TClientIdentitySecret;

type TClientIdentityProperties = {
  key: string;
  value: string;
}

type TClientIdentitySecret = {
  value: string;
  expiration: Date | string | null;
}

export class ClientIdentity {
  clientId: string;
  clientName: string;
  enabled: boolean;
  postLogoutRedirectUris: string[];
  redirectUris: string[];
  allowedCorsOrigins: string[];
  allowedGrantTypes: string[];
  allowedScopes: string[];
  properties: Array<TClientIdentityProperties>;
  secrets: Array<TClientIdentitySecret>;
  public constructor(_client?: ClientIdentity) {
    this.clientId = _client?.clientId ?? '';
    this.clientName = _client?.clientName ?? '';
    this.enabled = _client?.enabled ?? true;
    this.postLogoutRedirectUris = _client?.postLogoutRedirectUris ?? [];
    this.redirectUris = _client?.redirectUris ?? [];
    this.allowedCorsOrigins = _client?.allowedCorsOrigins ?? [];
    this.allowedGrantTypes = _client?.allowedGrantTypes ?? [];
    this.allowedScopes = _client?.allowedScopes ?? [];
    this.properties = _client?.properties ?? [{
      key: 'InstanceToken',
      value: ''
    }];
    this.secrets = _client?.secrets ?? [{
      value: Guid.newGuid(),
      expiration: null
    }]
  }
}

export class ClientConfiguration {
    scopes_supported: [
      'roles',
      'openid',
      'profile',
      'email',
      'instance_token',
      'isCMUser',
      'IdentityServerApi',
      'connectors.ro',
      'connectors.wo',
      'digitaltwin.ro',
      'digitaltwin.wo',
      'corporatemanagement.ro',
      'corporatemanagement.wo',
      'teia.ro',
      'teia.wo',
      'offline_access'
  ]= [
    'roles',
    'openid',
    'profile',
    'email',
    'instance_token',
    'isCMUser',
    'IdentityServerApi',
    'connectors.ro',
    'connectors.wo',
    'digitaltwin.ro',
    'digitaltwin.wo',
    'corporatemanagement.ro',
    'corporatemanagement.wo',
    'teia.ro',
    'teia.wo',
    'offline_access'
];

    grant_types_supported: [
        'authorization_code',
        'client_credentials',
        'refresh_token',
        'implicit',
        'password',
        'urn:ietf:params:oauth:grant-type:device_code'
    ] = [
      'authorization_code',
      'client_credentials',
      'refresh_token',
      'implicit',
      'password',
      'urn:ietf:params:oauth:grant-type:device_code'
  ];
}

export interface IClientIdentityApi extends ClientIdentity {
  configurationClientId: number;
}
