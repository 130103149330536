import React, { FC, useEffect, useState } from 'react';
import { Dropdown, Card, Form } from 'react-bootstrap';
import { LicenseDTO } from '../../interface/License/LicenseDTO';
import { licenseApi } from '../../api/licenseApi';
import HistoryLicenseModal from '../Modals/License/HistoryLicenseModal';
import { accountApi } from '../../api/accountApi';
import CreateLicenseModal from '../Modals/License/CreateLicenseModal';
import { Account } from '../../interface/account';
import { Instance } from '../../models/instance';
import { tradLicense } from './CardKeyTranslation';

interface LicenseCardProps {
    originalInstance: Instance | undefined;
    reloadData: boolean;
    setReloaData: Function;
}
const LicenseCard: FC<LicenseCardProps> = (props) => {
    const { tradLicenseInfo, tradLicenseLabel, tradLicenseAddLicense, tradLicenseHistory, tradLicenseDateCreation, tradLicenseProjectNbr, tradLicenseUserNbr, tradLicenseDateExp } = tradLicense();
    const { originalInstance, reloadData, setReloaData } = props;
    const [showLicenseHistoryModal, setShowLicenseHistoryModal] = useState<boolean>(false);
    const [accounts, setAccounts] = useState<Array<Account>>([]);
    const [showModalCreateLicenses, setShowModalCreateLicenses] = useState<boolean>(false);
    const [license, setLicense] = useState<LicenseDTO>({
        licenseId: 0,
        accountName: '',
        instanceName: '',
        instanceId: 0,
        instanceType: '',
        usage: '',
        instanceToken: '',
        licenseState: '',
        licenseType: '',
        licenseCreatedBy: '',
        licenseUsersCount: 0,
        licenseProjectsCount: 0,
        licenseExpirationDate: '',
        licenseExpiratedAt: '',
        licenseCreationDate: '',
        licenseSerial: '',
        creationLicenseDate: '',
        expirationLicenseDate: '',
        expirationLicenseAt: ''
    });

    useEffect(() => {
        if (originalInstance?.licenseId) {
            const fetchInstance = async () => {
                const result = await licenseApi.getLicenseById(originalInstance?.licenseId);
                setLicense(result);
            }
            fetchInstance();
        }
    }, [originalInstance?.licenseId]);

    useEffect(() => {
        const fetchAccounts = async () => {
            const result = await accountApi.getAll();
            setAccounts(result ?? []);
        }
        fetchAccounts();
    }, []);

    return (
        <>
            <CreateLicenseModal accounts={accounts} originalInstance={originalInstance} show={showModalCreateLicenses} setShow={setShowModalCreateLicenses} refreshData={reloadData} setRefreshData={setReloaData} />
            <HistoryLicenseModal show={showLicenseHistoryModal} setShow={setShowLicenseHistoryModal} instanceId={license.instanceId} />
            <Card className='card-table'>
                <Card.Header className='p-4 d-flex justify-content-between align-items-center'>
                    <h4 className='card-heading' >
                        {tradLicenseInfo}
                    </h4>
                    <Dropdown style={{ height: '17px' }}>
                        <Dropdown.Toggle variant="primary" >
                            {tradLicenseLabel}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={(event) => setShowModalCreateLicenses(true)}>{tradLicenseAddLicense}</Dropdown.Item>
                            <Dropdown.Item onClick={(event) => setShowLicenseHistoryModal(true)}>{tradLicenseHistory}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Card.Header>
                <Card.Body className='justify-content-end my-1 mx-5'>
                    <Form>
                        <Form.Group>
                            <Form.Label> {tradLicenseDateCreation} :</Form.Label>
                            {license?.licenseCreationDate && <Form.Label className='mx-2'> {new Date(license?.licenseCreationDate).toLocaleDateString('fr')}</Form.Label>}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label> {tradLicenseProjectNbr} :</Form.Label>
                            <Form.Label className='mx-2'> {license?.licenseProjectsCount ?? ''}</Form.Label>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label> {tradLicenseUserNbr} :</Form.Label>
                            <Form.Label className='mx-2'> {license?.licenseUsersCount ?? ''}</Form.Label>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label> {tradLicenseDateExp} :</Form.Label>
                            {license?.licenseExpirationDate && <Form.Label className='mx-2'> {new Date(license?.licenseExpirationDate).toLocaleDateString('fr')}</Form.Label>}
                        </Form.Group>
                    </Form>
                </Card.Body>
            </Card>
        </>
    );
}

export default LicenseCard;
