import React, { FC } from 'react';
import { Button, Form, InputGroup, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import useCopyToClipboard from '../../../hooks/CopyToClipboard';
import { ClientIdentity } from '../../../interface/ClientIdentity/clientIdentity';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { tradClientIdentityForm, tradIcons } from './ClientIdentityKeyTranslation';

interface ClientIdentityDetailsProps {
  show: boolean;
  setShow: Function;
  clientDetail?: ClientIdentity;
}

const ClientIdentityDetails: FC<ClientIdentityDetailsProps> = ({ show, setShow, clientDetail }) => {
  const { tradClientIdentityFormTitle, tradClientIdentityFormName, tradClientIdentityFormId, tradClientIdentityFormRedirectUrl, tradClientIdentityFormPostRedirectUrl, tradClientIdentityFormAllowedCors, tradClientIdentityFormAllowedGrant, tradClientIdentityFormAllowedScopes } = tradClientIdentityForm();

  const [copy] = useCopyToClipboard();
  const { tradIconsCopy } = tradIcons();

  const copyButton = (toCopy: string) => {
    return (
      <OverlayTrigger placement="top" delay={{ show: 250, hide: 250 }} overlay={<Tooltip>{tradIconsCopy}</Tooltip>}>
        <Button aria-label={tradIconsCopy} variant="outline-secondary" onClick={() => copy(toCopy)}>
          <FontAwesomeIcon icon={faCopy} />
        </Button>
      </OverlayTrigger>
    )
  }

  return (
    <Modal size="lg" show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{tradClientIdentityFormTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>{tradClientIdentityFormName}</Form.Label>
            <Form.Control readOnly disabled type="text" defaultValue={clientDetail?.clientName}></Form.Control>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{tradClientIdentityFormId}</Form.Label>
            <InputGroup>
              <Form.Control readOnly disabled type="text" defaultValue={clientDetail?.clientId}></Form.Control>
              {copyButton(clientDetail?.clientId as string)}
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{tradClientIdentityFormPostRedirectUrl}</Form.Label>
            <>
              {clientDetail &&
                clientDetail.postLogoutRedirectUris.map((field, index) => (
                  <InputGroup key={index} className="mb-2">
                    <Form.Control type="text" disabled readOnly defaultValue={field} />
                    {copyButton(field)}
                  </InputGroup>
                ))}
            </>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{tradClientIdentityFormRedirectUrl}</Form.Label>
            <>
              {clientDetail &&
                clientDetail.redirectUris.map((field, index) => (
                  <InputGroup key={index} className="mb-2">
                    <Form.Control type="text" disabled readOnly defaultValue={field} />
                    {copyButton(field)}
                  </InputGroup>
                ))}
            </>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{tradClientIdentityFormAllowedCors}</Form.Label>
            <>
              {clientDetail &&
                clientDetail.allowedCorsOrigins.map((field, index) => (
                  <InputGroup key={index} className="mb-2">
                    <Form.Control type="text" disabled readOnly defaultValue={field} />
                    {copyButton(field)}
                  </InputGroup>
                ))}
            </>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{tradClientIdentityFormAllowedGrant}</Form.Label>
            <>
              {clientDetail &&
                clientDetail.allowedGrantTypes.map((field, index) => (
                  <InputGroup key={index} className="mb-2">
                    <Form.Control type="text" disabled readOnly defaultValue={field} />
                    {copyButton(field)}
                  </InputGroup>
                ))}
            </>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{tradClientIdentityFormAllowedScopes}</Form.Label>
            <>
              {clientDetail &&
                clientDetail.allowedScopes.map((field, index) => (
                  <InputGroup key={index} className="mb-2">
                    <Form.Control type="text" placeholder="https://..." disabled readOnly defaultValue={field} />
                    {copyButton(field)}
                  </InputGroup>
                ))}
            </>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ClientIdentityDetails;
