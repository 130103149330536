import React, { FC } from 'react';
import { Card, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { LicenseDTO } from '../../interface/License/LicenseDTO';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseye } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { tradDashboardLicense } from './CardKeyTranslation';

interface DashboardLicenseCardProps {
  licenses: Array<LicenseDTO>;
  className: string;
}

const DashboardLicenseCard: FC<DashboardLicenseCardProps> = (props) => {
  const { tradDashboardLicenseDate, tradDashboardLicenseExpiredSoon } = tradDashboardLicense();
  const navigate = useNavigate();
  const { licenses, className } = props;
  const convertDate = (dateText: string) => {
    const date = new Date(dateText);
    return date.toLocaleDateString(tradDashboardLicenseDate);
  };
  const goToInstanceDetails = (InstanceId: number) => {
    navigate(`/instances/${InstanceId}`);
  };

  return (
    <Card className={`${className} card-table m-3`} style={{ height: 'fit-content' }}>
      <Card.Header className="p-4 d-flex justify-content-between align-items-center">
        <h4 className="card-heading">{tradDashboardLicenseExpiredSoon}</h4>
      </Card.Header>
      <Card.Body>
        <Table hover responsive className='st-min-w-full st-divide-y st-divide-gray-300'>
          <tbody>
            {licenses.sort((a, b) => new Date(b.licenseExpirationDate).valueOf() - new Date(a.licenseExpirationDate).valueOf()).map((license) => {
              const hashSerial = license.licenseSerial.split('-');
              return (
                <tr key={license.licenseId} style={{ cursor: 'pointer' }} onClick={() => goToInstanceDetails(license.instanceId)}>
                  <td>
                    <div className="st-font-medium st-text-gray-900 d-flex align-items-center st-place-content-start">
                      <FontAwesomeIcon className={`${license.licenseState === 'EXPIRED' ? 'text-danger' : 'text-warning'} st-mr-2`} icon={faBullseye} />
                      <OverlayTrigger placement="top" delay={{ show: 250, hide: 300 }} overlay={<Tooltip >{license.instanceName}</Tooltip>}>
                        <span className="text-truncate" style={{ maxWidth: '200px', display: 'inline-block', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                          {license.instanceName}
                        </span>
                      </OverlayTrigger>
                    </div>
                  </td>
                  <td className="st-text-gray-500">{hashSerial[hashSerial.length - 1]}</td>
                  <td className="st-text-gray-500">{license.licenseCreatedBy}</td>
                  <td className="st-text-gray-500">{convertDate(license.licenseExpirationDate)}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default DashboardLicenseCard;
