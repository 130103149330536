export class StereoApplication {
    applicationId : number=0;
    instanceId: number=0;
    url: string='';
    type: number=1;
    creationDate?: string='';
    createdBy: string='';
    instanceToken: string='';
    accountName: string=''
}
