import { deleteCookie, getCookie, setCookie } from './cookies';
import randomstring from 'randomstring';
import * as crypto from 'crypto';
import base64url from 'base64url';

const cookieName = 'Stereograph.Identity.Token';
const idTokenCookieName = 'Stereograph.Identity.IdToken';
const callbackUrl = `${process.env.REACT_APP_BASE_URL}/authentication/callback`

export const getCodeVerifier = () => {
  return localStorage.getItem('code_verifier') ?? '';
}
const setCodeVerifier = (value:string) => {
  return localStorage.setItem('code_verifier', value);
}
export const getCodeChallenge = () => {
  return localStorage.getItem('code_challenge') ?? '';
}
const setCodeChallenge = (value:string) => {
  return localStorage.setItem('code_challenge', value);
}

export const resetCode = () => {
  setCodeVerifier('');
  setCodeChallenge('');
}

export const getToken = () => {
  return getCookie(cookieName);
}

export const getIdToken = () => {
  return getCookie(idTokenCookieName);
}

export const setToken = (token:string, idToken:string, expiresIn?:number) => {
  setCookie(idTokenCookieName, idToken, expiresIn);
  return setCookie(cookieName, token, expiresIn);
}

export const resetToken = () => {
  deleteCookie(idTokenCookieName);
  return deleteCookie(cookieName);
}

export const isAuthenticated = () => {
  const jwt = getCookie(cookieName);
  if (jwt.length > 0) return true;
  return false;
}

export const generateCodeVerifier = () => {
  const codeVerifier = randomstring.generate(128);
  return codeVerifier;
}

export const generateCodeChallenge = (codeVerifier: string) => {
  const base64Digest = crypto
    .createHash('sha256')
    .update(codeVerifier)
    .digest('base64');

  const codeChallenge = base64url.fromBase64(base64Digest);
  return codeChallenge;
}

export const authenticationFlow = (returnUrl: string) => {
  if (!isAuthenticated()) {
    // Generate codes for OIDC
    const codeVerifier = generateCodeVerifier();
    setCodeVerifier(codeVerifier);
    setCodeChallenge(generateCodeChallenge(codeVerifier))

    const loginUrl = `${process.env.REACT_APP_IDENTITY_URL}/connect/authorize?response_type=code&client_id=corporatemanagement.client&scope=corporatemanagement.ro corporatemanagement.wo profile isCMUser IdentityServerApi openid email&redirect_uri=${encodeURI(callbackUrl)}&code_challenge=${getCodeChallenge()}&code_challenge_method=S256`;
    window.location.href = loginUrl;
  } else {
    window.location.href = returnUrl;
  }
}
