import React, { useState } from 'react';
import Icon from '../Icon/Icon';
import { Collapse } from 'react-bootstrap';
import ActiveLink from '../ActiveLink/ActiveLink';
import { newBadgeStyle } from '../Tables/BadgesStyle';

const Sidebar = ({ sidebarShrink, data }:any) => {
  const [dropdown, setDropdown] = useState<any>({})
  const [activeParent, setActiveParent] = useState()
  const toggleDropdown = (e:any, name:any) => {
    e && e.preventDefault()
    setDropdown({ ...dropdown, [name]: !dropdown[name] })
  }

  return (
    <div
      className={`sidebar py-3 ${sidebarShrink ? 'shrink show' : ''}`}
      id="sidebar"
    >
      {data.map((block:any) => (
        <React.Fragment key={block.name}>
          <h6 className="sidebar-heading">{block.name}</h6>
          <ul className="list-unstyled">
            {block.items.map((item:any) => (
              <li key={item.name} className="sidebar-list-item">
                <ActiveLink {...item} active="active" className={`sidebar-link text-muted ${
                      activeParent === item.name ? 'active' : ''
                    }`} onClick={(e:any) =>
                      item.links
                        ? toggleDropdown(e, item.name)
                        : (setDropdown({}), setActiveParent(item.name))
                    }
                    data-bs-toggle={item.links && 'collapse'}
                    role={item.links && 'button'}
                    aria-expanded={dropdown[item.name]}>
                    <Icon className="svg-icon-md me-3" icon={item.icon} />
                    <span className="sidebar-link-title">{item.name}</span>
                </ActiveLink>
                {item.links && (
                  <Collapse in={dropdown[item.name]}>
                    <ul className="sidebar-menu list-unstyled">
                      {item.links.map((link:any) => (
                        <li key={link.name} className="sidebar-list-item">
                          <ActiveLink {...link} active="active"
                              className="sidebar-link text-muted"
                              onClick={() => setActiveParent(item.name)}
                            >
                              {link.name}
                              {link.new && (
                                <span
                                  /* bg="info" */
                                  className={newBadgeStyle}
                                >
                                  New
                                </span>
                              )}
                          </ActiveLink>
                        </li>
                      ))}
                    </ul>
                  </Collapse>
                )}
              </li>
            ))}
          </ul>
        </React.Fragment>
      ))}
    </div>
  )
}
export default Sidebar;
