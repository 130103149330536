import React, { FC } from 'react';
import { Card, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Instance } from '../../models/instance';
import { faBullseye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { tradDashboardInstance } from './CardKeyTranslation';

interface DashboardInstanceCardProps {
  instances: Array<Instance>;
  className?: string;
}

const DashboardInstanceCard: FC<DashboardInstanceCardProps> = (props) => {
  const { tradDashboardInstanceDate, tradDashboardInstanceRecently } = tradDashboardInstance();
  const navigate = useNavigate();
  const { instances, className } = props;
  const convertDate = (dateText: string) => {
    const date = new Date(dateText);
    return date.toLocaleDateString(tradDashboardInstanceDate);
  };
  const goToInstanceDetails = (InstanceId: number) => {
    navigate(`/instances/${InstanceId}`);
  };
  return (
    <Card className={`${className ?? ''} card-table m-3`} style={{ height: 'fit-content' }}>
      <Card.Header className="p-4 d-flex justify-content-between align-items-center">
        <h4 className="card-heading">{tradDashboardInstanceRecently}</h4>
      </Card.Header>
      <Card.Body>
        <Table hover responsive>
          <tbody>
            {instances.map((instance) => {
              return (
                <tr
                  key={instance.instanceId}
                  style={{ cursor: 'pointer' }}
                  onClick={() => goToInstanceDetails(instance.instanceId)}>
                  <div className="st-font-medium st-text-gray-900 d-flex align-items-center st-place-content-start">
                    <FontAwesomeIcon className="mx-1" icon={faBullseye} style={{ color: 'green' }} />
                    <OverlayTrigger placement="top" delay={{ show: 250, hide: 300 }} overlay={<Tooltip>{instance.name}</Tooltip>}>
                      <span
                        className="text-truncate"
                        style={{ maxWidth: '150px', display: 'inline-block', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                        {instance.name}
                      </span>
                    </OverlayTrigger>
                  </div>
                  <td className="st-text-gray-500">{convertDate(instance.createdAt)}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default DashboardInstanceCard;
