export const saasBadgeStyle = 'st-inline-flex st-items-center st-rounded-md st-bg-gray-200 st-px-1.5 st-py-0.5 st-text-xs st-font-medium st-text-gray-700'
export const onPremiseBadgeStyle = 'st-inline-flex st-items-center st-rounded-md st-bg-gray-500 st-px-1.5 st-py-0.5 st-text-xs st-font-medium st-text-gray-100'

export const validBadgeStyle = 'st-inline-flex st-items-center st-rounded-md st-bg-green-200 st-px-1.5 st-py-0.5 st-text-xs st-font-medium st-text-green-700'
export const expiredBadgeStyle = 'st-inline-flex st-items-center st-rounded-md st-bg-red-200 st-px-1.5 st-py-0.5 st-text-xs st-font-medium st-text-red-700'
export const expiredSoonBadgeStyle = 'st-inline-flex st-items-center st-rounded-md st-bg-yellow-300 st-px-1.5 st-py-0.5 st-text-xs st-font-medium st-text-yellow-700'
export const archivedBadgeStyle = 'st-inline-flex st-items-center st-rounded-md st-bg-gray-400 st-px-1.5 st-py-0.5 st-text-xs st-font-medium st-text-gray-900'

export const proddBadgeStyle = 'st-inline-flex st-items-center st-rounded-md st-bg-blue-300 st-px-1.5 st-py-0.5 st-text-xs st-font-medium st-text-blue-800'
export const preProddBadgeStyle = 'st-inline-flex st-items-center st-rounded-md st-bg-indigo-300 st-px-1.5 st-py-0.5 st-text-xs st-font-medium st-text-indigo-800'
export const demoBadgeStyle = 'st-inline-flex st-items-center st-rounded-md st-bg-purple-300 st-px-1.5 st-py-0.5 st-text-xs st-font-medium st-text-purple-800'
export const sdkBadgeStyle = 'st-inline-flex st-items-center st-rounded-md st-bg-pink-300 st-px-1.5 st-py-0.5 st-text-xs st-font-medium st-text-pink-800'

export const rolesBadgeStyle = 'st-inline-flex st-items-center st-rounded-md st-bg-blue-300 st-px-1.5 st-py-0.5 st-text-xs st-font-medium st-text-blue-800'

export const enabledBadgeStyle = 'st-inline-flex st-items-center st-rounded-md st-bg-green-200 st-px-1.5 st-py-0.5 st-text-xs st-font-medium st-text-green-700'
export const disabledBadgeStyle = 'st-inline-flex st-items-center st-rounded-md st-bg-red-200 st-px-1.5 st-py-0.5 st-text-xs st-font-medium st-text-red-700'

export const newBadgeStyle = 'st-inline-flex st-items-center st-rounded-md st-bg-green-200 st-px-1.5 st-py-0.5 st-text-xs st-font-medium st-text-green-700'
