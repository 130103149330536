import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { licenseApi } from '../../../api/licenseApi';
import { LicenseDTO } from '../../../interface/License/LicenseDTO';
import { DataTable, GenericFilter } from '@stereograph/storybook';
import { perPageOptions } from '../../../utils/files-utils';
import { CellProps } from 'react-table';
import useCopyToClipboard from '../../../hooks/CopyToClipboard';
import { tradHistoryHeader, tradHistoryKeys, tradHistoryState, tradHistoryType } from './HistoryLicenceTableKeyTranslation';

interface HistoryLicenseProps {
  instanceId: number;
}

const HistoryLicenseTable: FC<HistoryLicenseProps> = (props) => {
  const { tradHeaderDate, tradHeaderCreatedBy, tradHeaderLimites, tradHeaderSerial, tradHeaderType, tradHeaderState } = tradHistoryHeader();
  const { tradTypeProd, tradTypePreProd, tradTypeSdk, tradTypeDemo } = tradHistoryType();
  const { tradStateValid, tradStateOk, tradStateExpiredSoon, tradStateExpired } = tradHistoryState();
  const { tradKeysSortPage, tradKeysFormat, tradSearchPlaceholder } = tradHistoryKeys();
  const { instanceId } = props;
  const [licenses, setLicenses] = useState<Array<LicenseDTO>>([]);
  const [filterValue, setFilterValue] = useState('');
  const [copy] = useCopyToClipboard();

  useEffect(() => {
    if (instanceId) {
      const fetchLicenses = async () => {
        const result = await licenseApi.getAllByInstanceId(instanceId);
        setLicenses(result);
      }
      fetchLicenses();
    }
  }, [instanceId]);

  const handleFilter = (event: ChangeEvent) => {
    setFilterValue((event.target as HTMLInputElement).value)
  }

  const columnsDefinition = useMemo(() => [
    {
      Header: tradHeaderDate,
      accessor: 'licenseCreationDate',
      Cell: ({ cell }: CellProps<any>) => {
        return cell.row.original.licenseCreationDate.split('T')[0]
      }
    },
    {
      Header: tradHeaderCreatedBy,
      accessor: 'licenseCreatedBy'
    },
    {
      Header: tradHeaderLimites,
      accessor: '',
      Cell: ({ cell }: CellProps<any>) => {
        return (
          <>
            <span className='d-block'>
              {cell.row.original.licenseUsersCount} Users
            </span>
            <span className='d-block'>
              {cell.row.original.licenseProjectsCount} Projects
            </span>
            <span className='d-block'>
              {cell.row.original.licenseExpirationDate.split('T')[0]}
            </span>
          </>
        )
      }
    },
    {
      Header: tradHeaderSerial,
      accessor: 'licenseSerial',
      Cell: ({ cell }: CellProps<any>) => {
        const hashKey = cell.row.original.licenseSerial.split('-');
        return (
          <div className='d-flex align-items-center'>
            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>{cell.row.original.licenseSerial}</Tooltip>}>
              <p className='text-center'>{hashKey[hashKey.length - 1]}</p>
            </OverlayTrigger>
            <Button className='fas fa-check-square mx-3 px-2' onClick={() => { copy(cell.row.original.licenseSerial) }}>
              <FontAwesomeIcon icon={faCopy} />
            </Button>
          </div>
        )
      }
    },
    {
      Header: tradHeaderType,
      accessor: 'usage',
      Cell: ({ cell }: CellProps<any>) => {
        switch (cell.row.original.usage) {
          case 'PROD':
            return tradTypeProd;
          case 'PREPROD':
            return tradTypePreProd;
          case 'SDK':
            return tradTypeSdk;
          case 'DEMO':
            return tradTypeDemo;
          default:
            break;
        }
      }
    },
    {
      Header: tradHeaderState,
      accessor: 'licenseState',
      Cell: ({ cell }: CellProps<any>) => {
        switch (cell.row.original.licenseState) {
          case 'VALID':
            return tradStateValid;
          case 'OK':
            return tradStateOk;
          case 'EXPIREDSOON':
            return tradStateExpiredSoon;
          case 'EXPIRED':
            return tradStateExpired;
          default:
            break;
        }
      }
    }

  ], [licenses])

  const filteredLicenses = filterValue ? licenses.filter((license) => license.licenseCreatedBy.toLowerCase().includes(filterValue.toLowerCase())) : licenses;

  return (
    <DataTable
      columns={columnsDefinition}
      perPageOptions={perPageOptions}
      items={filteredLicenses}
      labelPage={tradKeysSortPage}
      lang={tradKeysFormat}
      genericFilterValue={filterValue} >
      <GenericFilter
        placeholder={tradSearchPlaceholder}
        handleChange={handleFilter} />
    </DataTable>
  )
}

export default HistoryLicenseTable;
