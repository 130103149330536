import React, { FC, useContext } from 'react';
import { licenseApi } from '../../../api/licenseApi';
import { ToasterContext } from '../../../pages/App/App';
import { CustomModal } from '@stereograph/storybook/dist/components/CustomModal/CustomModal';
import { License } from '../../../interface/License/License';
import { tradToaster, tradDelete } from './LicenseKeyTranslation';

interface LicenseFormProps {
  show: boolean;
  setShow: Function;
  license: License;
  refreshData: boolean;
  setRefreshData: Function;
}

const DeleteLicenseModal: FC<LicenseFormProps> = (props) => {
  const { tradToasterSuccess, tradToasterSuccessDelete } = tradToaster();
  const { tradDeleteCancel, tradDeleteConfirm, tradDeleteTitle, tradDeleteDelete } = tradDelete();
  const { pushToast } = useContext(ToasterContext);
  const { show, setShow, license, refreshData, setRefreshData } = props;

  const deleteLicense = async () => {
    const result = await licenseApi.delete(license);
    if (result?.status === 204) {
      pushToast({
        type: 'success',
        title: tradToasterSuccess,
        message: tradToasterSuccessDelete,
        autoHide: true
      });
      setRefreshData(!refreshData);
    }
    setShow(false);
  }
  return (
    <CustomModal
      show={show}
      setShow={setShow}
      onAction={deleteLicense}
      cancelButton={tradDeleteCancel}
      actionName={tradDeleteConfirm}
      type="danger"
      title={tradDeleteTitle}>
      {tradDeleteDelete}
    </CustomModal>
  )
}

export default DeleteLicenseModal;
