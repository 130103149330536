import axios from '../middleware/requestInterceptor';

export const rolesApi = {
  async getAll(instanceId?: string) {
    const result = await axios.get(`/instances/${instanceId}/roles`)
    return result.data;
  },
  async create(instanceId: number, name: string) {
    const result = await axios.post(`/instances/${instanceId}/roles`, { instanceId, name })
    return result;
  }
}
