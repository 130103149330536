import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';

export interface ToasterProps {
  message?: string | (() => React.ReactNode);
  type: 'success' | 'error' | 'info';
  title: String;
  class?: String | undefined;
  autoHide?: boolean;
  onClose?: () => void;
}

export const ToasterInfo: FC<ToasterProps> = (props: ToasterProps) => {
  const { message } = props;
  const [isToastDisplayed, setIsToastDisplayed] = useState(false);

  useEffect(() => {
    if (!isToastDisplayed) {
      if (props.type === 'info') {
        toast(message as string, {
          icon: <FontAwesomeIcon icon={faInfoCircle} className="text-primary" />
        });
      } else {
        toast[props.type](message as string);
      }
      setIsToastDisplayed(true);
    }
  }, [isToastDisplayed, props, message]);

  return (
    <React.Fragment>
      <Toaster position="top-right" />
    </React.Fragment>
  );
};

export default ToasterInfo;
