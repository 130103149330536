import { t } from 'i18next';
import { translatedText } from '../../../i18n/config';

export function tradHeader() {
    const tradHeaderAccount = translatedText('license.account', t);
    const tradHeaderInstance = translatedText('license.instance', t);
    const tradHeaderType = translatedText('license.instanceType', t);
    const tradHeaderInstanceUsage = translatedText('license.instanceUsage', t);
    const tradHeaderState = translatedText('license.state', t);
    const tradHeaderLimites = translatedText('license.limites', t);
    const tradHeaderExpiration = translatedText('license.expirationdDate', t);
    const tradHeaderCreated = translatedText('license.createdAt', t);
    const tradHeaderLabel = translatedText('actions.label', t);
    return { tradHeaderAccount, tradHeaderInstance, tradHeaderType, tradHeaderInstanceUsage, tradHeaderState, tradHeaderLimites, tradHeaderExpiration, tradHeaderCreated, tradHeaderLabel };
}

export function tradState() {
    const tradStateValid = translatedText('license.valid', t);
    const tradStateOk = translatedText('license.ok', t);
    const tradStateExpiredSoon = translatedText('license.expired_soon', t);
    const tradStateExpired = translatedText('license.expired', t);
    return { tradStateValid, tradStateOk, tradStateExpiredSoon, tradStateExpired };
}

export function tradLabel() {
    const tradLabelDelete = translatedText('delete', t);
    const tradLabelCopy = translatedText('license.copy', t);
    return { tradLabelDelete, tradLabelCopy };
}

export function tradTable() {
    const tradTableTitle = translatedText('license.title', t);
    const tradTableAddLicense = translatedText('license.add_new_licenses', t);
    const tradTableSortPage = translatedText('sort_per_page', t);
    const tradTableDate = translatedText('date.format', t);
    const tradTableSearch = translatedText('instances.search_by', t);
    const tradTableInstanceToken = translatedText('placeholder.instance_token', t);
    const tradTableAccount = translatedText('placeholder.accounts', t);
    const tradTableInstance = translatedText('placeholder.instance', t);
    const tradTableLicenseState = translatedText('placeholder.license_state', t);
    return { tradTableTitle, tradTableAddLicense, tradTableSortPage, tradTableDate, tradTableSearch, tradTableInstanceToken, tradTableAccount, tradTableInstance, tradTableLicenseState };
}

export function tradType() {
    const tradTypeProd = translatedText('instance.prod', t);
    const tradTypePreProd = translatedText('instance.preprod', t);
    const tradTypeSdk = translatedText('instance.sdk', t);
    const tradTypeDemo = translatedText('instance.demo', t);
    return { tradTypeProd, tradTypePreProd, tradTypeSdk, tradTypeDemo };
}
