import React, { FC } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import AccountTable from '../../components/Tables/AccountTable/AccountTable';

const Account: FC = () => {
  return (
    <Container>
      <Row>
        <Col lg={12} md={12}>
          <AccountTable />
        </Col >
      </Row>
    </Container>
  )
}

export default Account;
