/* eslint-disable no-unused-vars */
import { t } from 'i18next';
import { translatedText } from '../../i18n/config';

export function tradHome() {
    const tradHomeTitle = translatedText('home.title', t);
    const tradHomelogin = translatedText('login', t);
    const tradHomeImageDesc = translatedText('home.description', t);
    return { tradHomeTitle, tradHomelogin, tradHomeImageDesc };
}
