import { t } from 'i18next';
import { translatedText } from '../../../i18n/config';

export function tradToaster() {
    const tradToasterSuccess = translatedText('messages.success', t);
    const tradToasterDeleteSuccess = translatedText('client.delete.success', t);
    return { tradToasterSuccess, tradToasterDeleteSuccess };
}

export function tradHeader() {
    const tradHeaderClientName = translatedText('client.clientName', t);
    const tradHeaderClientId = translatedText('client.clientId', t);
    const tradHeaderEnabled = translatedText('client.enabled', t);
    const tradHeaderDisabled = translatedText('client.disabled', t);
    const tradHeaderRedirectUris = translatedText('client.redirectUris', t);
    const tradHeaderAllowedCorsOrigins = translatedText('client.allowedCorsOrigins', t);
    const tradHeaderAllowedScopes = translatedText('client.allowedScopes', t);
    const tradHeaderLabel = translatedText('actions.label', t);
    return { tradHeaderClientName, tradHeaderClientId, tradHeaderEnabled, tradHeaderDisabled, tradHeaderRedirectUris, tradHeaderAllowedCorsOrigins, tradHeaderAllowedScopes, tradHeaderLabel };
}

export function tradTable() {
    const tradTableSeeDetails = translatedText('instance.see_details', t);
    const tradTableUpdate = translatedText('update', t);
    const tradTableDelete = translatedText('delete', t);
    const tradTableSortPage = translatedText('sort_per_page', t);
    const tradTabledate = translatedText('date.format', t);
    const tradTableSearch = translatedText('placeholder.search', t);
    return { tradTableSeeDetails, tradTableUpdate, tradTableDelete, tradTableSortPage, tradTabledate, tradTableSearch };
}
