import { t } from 'i18next';
import { translatedText } from '../../../i18n/config';

export function tradHistoryHeader() {
    const tradHeaderDate = translatedText('license.createdAt', t);
    const tradHeaderCreatedBy = translatedText('instance.createdBy', t);
    const tradHeaderLimites = translatedText('license.limites', t);
    const tradHeaderSerial = translatedText('serial', t);
    const tradHeaderType = translatedText('type', t);
    const tradHeaderState = translatedText('state', t);
    return { tradHeaderDate, tradHeaderCreatedBy, tradHeaderLimites, tradHeaderSerial, tradHeaderType, tradHeaderState };
}

export function tradHistoryType() {
    const tradTypeProd = translatedText('instance.prod', t);
    const tradTypePreProd = translatedText('instance.preprod', t);
    const tradTypeSdk = translatedText('instance.sdk', t);
    const tradTypeDemo = translatedText('instance.demo', t);
    return { tradTypeProd, tradTypePreProd, tradTypeSdk, tradTypeDemo };
}

export function tradHistoryState() {
    const tradStateValid = translatedText('license.valid', t);
    const tradStateOk = translatedText('license.ok', t);
    const tradStateExpiredSoon = translatedText('license.expired_soon', t);
    const tradStateExpired = translatedText('license.expired', t);
    return { tradStateValid, tradStateOk, tradStateExpiredSoon, tradStateExpired };
}

export function tradHistoryKeys() {
    const tradKeysSortPage = translatedText('sort_per_page', t);
    const tradKeysFormat = translatedText('date.format', t);
    const tradSearchPlaceholder = translatedText('placeholder.search', t);
    return { tradKeysSortPage, tradKeysFormat, tradSearchPlaceholder };
}
