import { AxiosError, AxiosResponse } from 'axios';
import React, { FC, useContext } from 'react';
import { ToasterContext } from '../../pages/App/App';
import customAxios from '../../middleware/requestInterceptor';
import { tradErrorToaster } from './ErrorInterceptorKeyTranslation';

class MessagePolicy {
  urlRegex!: RegExp;
  method!: string;
  message!: string;

  public constructor(_urlRegex: RegExp, _method: string, _message: string) {
    this.urlRegex = _urlRegex;
    this.method = _method;
    this.message = _message;
  }
}

const ErrorInterceptor: FC = () => {
  const { tradErrorToasterError } = tradErrorToaster();
  const { pushToast } = useContext(ToasterContext);
  const onResponse = (response: AxiosResponse): AxiosResponse => {
    return response;
  };

  const onResponseError = (error: any): Promise<AxiosError> => {
    const { response } = error;
    let filteredMessages: MessagePolicy | undefined;

    function messageError(urlRegex: RegExp, method: string) {
      return new MessagePolicy(urlRegex, method, response?.data?.validations?.[0]);
    }
    const messages = [
      ...['applications', 'delegations$', 'clients$'].map(endURL => messageError(new RegExp(`/instances/[0-9]+/${endURL}/`), 'post')),
      ...['instances$', 'clients/$'].map(endURL => messageError(new RegExp(`/instances/[0-9]+/${endURL}/`), 'get')),
      ...['get', 'post'].map(method => messageError(/\/accounts$/, method)),
      ...['put', 'delete'].map(method => messageError(/\/accounts\/[0-9]+$/, method)),
      ...['get', 'post'].map(method => messageError(/\/licenses$/, method)),
      ...['get', 'delete'].map(method => messageError(/\/licenses\/[0-9]+$/, method)),
      ...['get', 'post'].map(method => messageError(/\/instances$/, method)),
      ...['get', 'put', 'delete'].map(method => messageError(/\/instances\/[0-9]+$/, method)),
      ...['get', 'post'].map(method => messageError(/\/instances\/[0-9]+\/roles$/, method)),
      ...['get', 'post'].map(method => messageError(/\/instances\/[0-9]+\/users$/, method)),
      ...['put', 'delete'].map(method => messageError(/\/instances\/[0-9]+\/applications\/[0-9a-zA-Z-]+$/g, method)),
      ...['put', 'delete'].map(method => messageError(/\/instances\/[0-9]+\/users\/[0-9a-zA-Z-]+$/, method)),
      ...['put', 'delete'].map(method => messageError(/\/instances\/[0-9]+\/delegations\/.*$/, method)),
      ...['put', 'delete'].map(method => messageError(/\/instances\/[0-9]+\/clients\/.*$/, method))
    ]

    if (response !== undefined || response !== null) {
      filteredMessages = messages.find(MessagePolicy => { return MessagePolicy.urlRegex.test(response.config.url) && MessagePolicy.method.toLowerCase() === response.config.method.toLowerCase() })
    }

    if (filteredMessages !== undefined) {
      pushToast({
        type: 'error',
        title: tradErrorToasterError,
        message: response?.data?.validations?.[0],
        autoHide: true
      });
    } else {
      pushToast({
        type: 'error',
        title: tradErrorToasterError,
        message: response?.data?.validations?.[0],
        autoHide: true
      });
    }

    if (response?.status === 401) {
      window.location.href = `${process.env.REACT_APP_BASE_URL}/forbidden`;
    }
    if (response?.status === 403) {
      window.location.href = `${process.env.REACT_APP_BASE_URL}/forbidden`;
    }
    return Promise.reject(error);
  };
  customAxios.interceptors.response.use(onResponse, onResponseError);
  return (<>
  </>)
}
export default ErrorInterceptor;
