/* eslint-disable no-unused-vars */
import { t } from 'i18next';
import { translatedText } from '../../../i18n/config';

export function tradClientIdentityForm() {
    const tradClientIdentityFormTitle = translatedText('DETAILS', t);
    const tradClientIdentityFormName = translatedText('client.clientName', t);
    const tradClientIdentityFormId = translatedText('client.clientId', t);
    const tradClientIdentityFormPostRedirectUrl = translatedText('client.postLogoutRedirectUris', t);
    const tradClientIdentityFormRedirectUrl = translatedText('client.redirectUris', t);
    const tradClientIdentityFormAllowedCors = translatedText('client.allowedCorsOrigins', t);
    const tradClientIdentityFormAllowedGrant = translatedText('client.allowedGrantTypes', t);
    const tradClientIdentityFormAllowedScopes = translatedText('client.allowedScopes', t);
    const tradClientIdentityFormSecrets = translatedText('client.secrets', t);
    return { tradClientIdentityFormTitle, tradClientIdentityFormName, tradClientIdentityFormId, tradClientIdentityFormRedirectUrl, tradClientIdentityFormPostRedirectUrl, tradClientIdentityFormAllowedCors, tradClientIdentityFormAllowedGrant, tradClientIdentityFormAllowedScopes, tradClientIdentityFormSecrets };
}

export function tradClientIdentityEdition() {
    const tradClientIdentityEditionUpdateClient = translatedText('client.update_client', t);
    const tradClientIdentityEditionAddClient = translatedText('client.add_new_client', t);
    const tradClientIdentityEditionCancel = translatedText('cancel', t);
    const tradClientIdentityEditionNameFeedback = translatedText('client.validation.clientName', t);
    const tradClientIdentityEditionIdFeedback = translatedText('client.validation.clientId', t);
    const tradClientIdentityEditionEnable = translatedText('client.enabled', t);
    const tradClientIdentityEditionDisable = translatedText('client.disabled', t);
    return { tradClientIdentityEditionUpdateClient, tradClientIdentityEditionAddClient, tradClientIdentityEditionCancel, tradClientIdentityEditionNameFeedback, tradClientIdentityEditionIdFeedback, tradClientIdentityEditionEnable, tradClientIdentityEditionDisable };
}

export function tradToaster() {
    const tradToasterSecretType = translatedText('messages.info', t);
    const tradToasterSecretMessage = translatedText('client.copy.client_secret', t);
    const tradToasterSuccess = translatedText('messages.success', t);
    const tradToasterPatchMessage = translatedText('client.patch.success', t);
    const tradToasterPostMessage = translatedText('client.post.success', t);
    return { tradToasterSecretType, tradToasterSecretMessage, tradToasterSuccess, tradToasterPatchMessage, tradToasterPostMessage };
}

export function tradIcons() {
    const tradIconsDelete = translatedText('client.action.delete', t);
    const tradIconsAdd = translatedText('client.action.add', t);
    const tradIconsCopy = translatedText('client.action.copy', t);
    return { tradIconsDelete, tradIconsAdd, tradIconsCopy };
}
