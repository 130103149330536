import React from 'react'

import { Button, Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAlignLeft } from '@fortawesome/free-solid-svg-icons'

export default function Header({ setSidebarShrink, sidebarShrink, link, projects, currentProject, allProjectsText }: any) {
  const allProjectsTexts = allProjectsText;

  return (
    <header className="header">
      <div
        data-bg="white"
        data-expand="lg"
        data-variant={false}
        className="px-4 py-2 shadow d-flex justify-content-between h-25"
      >
        <button
          className="sidebar-toggler text-gray-500 me-4 me-lg-5 lead"
          style={{ border: 'none', backgroundColor: 'transparent' }}
          onClick={() => setSidebarShrink(!sidebarShrink)}
        >
          <FontAwesomeIcon icon={faAlignLeft} />
        </button>
        <Dropdown>
          <Dropdown.Toggle variant="link">
            {currentProject ? currentProject.name : allProjectsTexts}
          </Dropdown.Toggle>
          <Dropdown.Menu className="text-center" style={{ minWidth: '250px', marginLeft: '-75px' }}>
            {
              projects && projects.map((item: any) => {
                return <Dropdown.Item key={item.id} href={`/projects/${item.id}`} > {item.name} </Dropdown.Item>
              })
            }
            <Button variant="outline-primary" className="m-2" href='/projects'>{allProjectsText}</Button>
          </Dropdown.Menu>
        </Dropdown>
        {link}
      </div>
    </header>
  )
}
