import { CustomModal } from '@stereograph/storybook/dist/components/CustomModal/CustomModal';
import React, { FC, useContext } from 'react';
import { delegationAPi } from '../../../api/delegationApi';
import { Delegation } from '../../../interface/delegation';
import { ToasterContext } from '../../../pages/App/App';
import DelegationForm from '../../Form/DelegationForm';
import { tradEdition, tradToaster } from './DelegationkeyTranslation';

interface DelegationFormProps {
    instanceId: number;
    delegation: Delegation;
    setDelegation: Function;
    show: boolean;
    setShow: Function;
    actionType: number;
    refreshDelegations: boolean;
    setRefreshDelegations: Function;
}

const DelegationEditionModal: FC<DelegationFormProps> = (props) => {
    const { tradToasterSuccess, tradToasterDelegationCreation, tradToasterDelegationUpdate } = tradToaster();
    const { tradEditionAddDelegation, tradEditionUpdateDelegation, tradEditionAdd, tradEditionCancel } = tradEdition();
    const { show, setShow, instanceId, delegation, setDelegation, actionType, refreshDelegations, setRefreshDelegations } = props;
    const { pushToast } = useContext(ToasterContext);
    const onNameChanged = (event) => {
        const newDelegation = Object.assign({}, delegation);
        newDelegation.name = event.target.value;
        setDelegation(newDelegation);
    }

    const onDiscoveryEndpointChanged = (event) => {
        const newDelegation = Object.assign({}, delegation);
        newDelegation.discoveryEndpoint = event.target.value;
        setDelegation(newDelegation);
    }

    const onClientIdChanged = (event) => {
        const newDelegation = Object.assign({}, delegation);
        newDelegation.clientId = event.target.value;
        setDelegation(newDelegation);
    }

    const onClientSecretChanged = (event) => {
        const newDelegation = Object.assign({}, delegation);
        newDelegation.clientSecret = event.target.value;
        setDelegation(newDelegation);
    }

    const onAuthorityChanged = (event) => {
        const newDelegation = Object.assign({}, delegation);
        newDelegation.authority = event.target.value;
        setDelegation(newDelegation);
    }

    const onAuthorizationEndpointChanged = (event) => {
        const newDelegation = Object.assign({}, delegation);
        newDelegation.authorizationEndpoint = event.target.value;
        setDelegation(newDelegation);
    }

    const onTokenEndpointChanged = (event) => {
        const newDelegation = Object.assign({}, delegation);
        newDelegation.tokenEndpoint = event.target.value;
        setDelegation(newDelegation);
    }

    const onUserInfoChanged = (event) => {
        const newDelegation = Object.assign({}, delegation);
        newDelegation.userInfoEndpoint = event.target.value;
        setDelegation(newDelegation);
    }

    const onChageDelegationType = (event) => {
        const newDelegation = Object.assign({}, delegation);
        newDelegation.roleStrategy = event.target.value;
        setDelegation(newDelegation);
    }

    const onChangeDelegationAutoRedirect = (event) => {
        const newDelegation = Object.assign({}, delegation);
        newDelegation.configuration.autoRedirect = event.target.checked;
        setDelegation(newDelegation);
    }

    const onChangeDelegationAutoConfirmEmail = (event) => {
        const newDelegation = Object.assign({}, delegation);
        newDelegation.configuration.autoConfirmEmail = event.target.checked;
        setDelegation(newDelegation);
    }

    const onChangeDelegationForceResetPassword = (event) => {
        const newDelegation = Object.assign({}, delegation);
        newDelegation.configuration.forceResetPassword = event.target.checked;
        setDelegation(newDelegation);
    }

    const onSubmitForm = async (delegation: Delegation) => {
        let result;
        switch (actionType) {
            case 1:
                result = await delegationAPi.create(+instanceId ?? 0, delegation);
                if (result?.status === 200) {
                    pushToast({
                        type: 'success',
                        title: tradToasterSuccess,
                        message: tradToasterDelegationCreation,
                        autoHide: true
                    });
                    setRefreshDelegations(!refreshDelegations);
                    setShow(!show);
                }
                break;
            case 2:
                result = await delegationAPi.update(+instanceId ?? 0, delegation);
                if (result?.status === 200 || result?.status === 204) {
                    pushToast({
                        type: 'success',
                        title: tradToasterSuccess,
                        message: tradToasterDelegationUpdate,
                        autoHide: true
                    });
                    setRefreshDelegations(!refreshDelegations);
                    setShow(!show);
                }
                break;
        }
        setRefreshDelegations(!refreshDelegations);
    }
    return (
        <CustomModal
            show={show}
            setShow={setShow}
            title={actionType === 1 ? tradEditionAddDelegation : tradEditionUpdateDelegation}
            actionName={actionType === 1 ? tradEditionAdd : tradEditionUpdateDelegation}
            onAction={() => onSubmitForm(delegation)}
            cancelButton={tradEditionCancel}
            type="primary"
            onClose={() => setShow(false)}>
            <DelegationForm
                delegation={delegation}
                onNameChanged={onNameChanged}
                onAuthorityChanged={onAuthorityChanged}
                onAuthorizationEndpointChanged={onAuthorizationEndpointChanged}
                onClientIdChanged={onClientIdChanged}
                onClientSecretChanged={onClientSecretChanged}
                onDiscoveryEndpointChanged={onDiscoveryEndpointChanged}
                onTokenEndpointChanged={onTokenEndpointChanged}
                onUserInfoChanged={onUserInfoChanged}
                onChageDelegationType={onChageDelegationType}
                onChangeDelegationAutoRedirect={onChangeDelegationAutoRedirect}
                onChangeDelegationAutoConfirmEmail={onChangeDelegationAutoConfirmEmail}
                onChangeDelegationForceResetPassword={onChangeDelegationForceResetPassword}
                isEdition={actionType === 2}
            >
            </DelegationForm>
        </CustomModal>)
}

export default DelegationEditionModal;
