import { StereoApplication } from './stereoApplication';

export class Instance {
    instanceId: number ;
    name: string ;
    type: string ;
    state: string ;
    licenseState: string ;
    licenseId: number ;
    usage: string ;
    createdAt: string ;
    createdBy: string ;
    createdLocaleDate: string;
    comment: string ;
    instanceToken: string ;
    accountName: string ;
    accountId: number ;
    applications: Array<StereoApplication> = [];
    environment: string;

    public constructor(_instance?: Instance) {
        this.instanceId = _instance?.instanceId ?? 0;
        this.name = _instance?.name ?? '';
        this.type = _instance?.type ?? '';
        this.state = _instance?.state ?? '';
        this.licenseState = _instance?.licenseState ?? '';
        this.licenseId = _instance?.licenseId ?? 0;
        this.usage = _instance?.usage ?? '';
        this.createdAt = _instance?.createdAt ?? '';
        this.createdBy = _instance?.createdBy ?? '';
        this.comment = _instance?.comment ?? '';
        this.instanceToken = _instance?.instanceToken ?? '';
        this.accountName = _instance?.accountName ?? '';
        this.accountId = _instance?.accountId ?? 0;
        this.applications = JSON.parse(JSON.stringify(_instance?.applications ?? []));
        this.createdLocaleDate = _instance?.createdLocaleDate ?? '';
        this.environment = _instance?.environment ?? '';
    }
}

export const Environment = {
    Demonstration: 'Demonstration',
    Integration: 'Integration',
    Preproduction: 'PreProduction',
    Production: 'Production'
}
