import React, { FC, useContext } from 'react';
import { ToasterContext } from '../../../pages/App/App';
import { CustomModal } from '@stereograph/storybook/dist/components/CustomModal/CustomModal';
import { userApi } from '../../../api/userApi';
import { Users } from '../../../interface/users';
import { Instance } from '../../../models/instance';
import { tradDelete, tradToaster } from './UserKeyTranslation';

interface FormProps {
    show: boolean;
    setShow: Function;
    currentInstance: Instance;
    refreshUser: Function;
    user: Users;
}

const DeleteUserModal: FC<FormProps> = (props) => {
    const { tradToasterSuccess, tradToasterSuccessDelete } = tradToaster();
    const { tradDeleteCancel, tradDeleteConfirm, tradDeleteTitle, tradDeleteDeleteConfirm } = tradDelete();
    const { pushToast } = useContext(ToasterContext);
    const { show, setShow, currentInstance, refreshUser, user } = props;

    const deleteUser = async () => {
        const deleteUser = await userApi.delete(currentInstance.instanceId, user.id);
        if (deleteUser?.status === 204) {
            pushToast({
                type: 'success',
                title: tradToasterSuccess,
                message: tradToasterSuccessDelete,
                autoHide: true
            })
            refreshUser();
        }
        setShow(false)
    }
    return (
        <CustomModal
            show={show}
            setShow={setShow}
            onAction={deleteUser}
            cancelButton={tradDeleteCancel}
            actionName={tradDeleteConfirm}
            type="danger"
            title={tradDeleteTitle}>
            {tradDeleteDeleteConfirm}
        </CustomModal>
    )
}

export default DeleteUserModal;
