import React, { FC } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import LicenseTable from '../../components/Tables/LicenseTable/LicenseTable';

const License: FC = () => {
  return (
    <Container>
      <Row>
        <Col lg={12} md={12}>
          <LicenseTable />
        </Col >
      </Row>
    </Container>
  )
}

export default License;
