import { ClientIdentity, ClientIdentityProperty } from '../interface/ClientIdentity/clientIdentity';

export const handleAddInputs = (initialState: ClientIdentity, propertyChange: string, addedValue: string | ClientIdentityProperty) => {
  const dirtyValue = initialState[propertyChange];
  dirtyValue.push(addedValue);
  return { ...initialState, [propertyChange]: dirtyValue }
};

export const handleRemoveInputs = (initialState: ClientIdentity, propertyChange: string, index: number) => {
  const newFormUris = initialState[propertyChange];
  newFormUris.splice(index, 1);
  return { ...initialState, [propertyChange]: newFormUris };
};

export const handleChange = (initialState: ClientIdentity, propertyChange: string, value: string | ClientIdentityProperty, index: number) => {
  const uris = initialState[propertyChange];
  uris[index] = value;
  return { ...initialState, [propertyChange]: uris };
};

export const handleChangeSecretsDate = (initialState: ClientIdentity, value: string, index: number) => {
  const secrets = initialState.secrets;
  secrets[index].expiration = new Date(value).toISOString();
  return { ...initialState, secrets };
};
