import React from 'react';

import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Icon = (props:any) => (
  <FontAwesomeIcon icon={props.icon} className={props.className ? props.className : ''} style={props.style}></FontAwesomeIcon>
)

export default Icon

Icon.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({
    width: PropTypes.string
  }),
  icon: PropTypes.any
}

Icon.defaultProps = {
  className: '',
  style: {
    width: '30 px'
  },
  icon: 'bank-cards-1'
}
