import React, { FC, useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Card from 'react-bootstrap/esm/Card';
import { clientIdentityApi } from '../../api/clientIdentityApi';
import { ClientConfiguration, IClientIdentityApi } from '../../interface/ClientIdentity/clientIdentity';
import ClientIdentityDetails from '../Modals/ClientIdentityModal/ClientIdentityDetails';
import ClientEditionModal from '../Modals/ClientIdentityModal/ClientIdentityEditionModal';
import ClientIdentityTable from '../Tables/ClientIdentityTable/ClientIdentityTable';
import { tradClientIdentity } from './CardKeyTranslation';

interface ClientCardProps {
  instanceId: number;
  instanceToken: string;
}

const ClientCard: FC<ClientCardProps> = ({ instanceToken, instanceId }) => {
  const { tradClientIdentityTitle, tradClientIdentityAddClient } = tradClientIdentity();
  const [showClientModal, setShowClientModal] = useState<boolean>(false);
  const [showClientDetail, setShowClientDetail] = useState<boolean>(false);
  const [clientIdentity, setClientIdentity] = useState<Array<IClientIdentityApi>>([]);
  const [configurationOpenId, setConfigurationOpenId] = useState<ClientConfiguration>(new ClientConfiguration());

  const clientIdentityRef = useRef();

  const fetchClientIdentity = async () => {
    const result = await clientIdentityApi.getAll(instanceId);
    setClientIdentity(result);
  }

  const fetchConfigurationOpenId = async () => {
    const result = await clientIdentityApi.getOpenIdConfiguration();
    setConfigurationOpenId(result);
  }

  useEffect(() => {
    fetchClientIdentity();
    fetchConfigurationOpenId();
  }, [])

  return (
    <>
      <Card className="card-table mt-4">
        <Card.Header className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <h4 className="card-heading">{tradClientIdentityTitle}</h4>
          </div>
          <div>
            <Button onClick={() => { setShowClientModal(true); clientIdentityRef.current = undefined; }}>
              {tradClientIdentityAddClient}
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          <ClientIdentityTable
            setShowClientModal={setShowClientModal}
            innerRef={clientIdentityRef}
            fetchClientIdentity={fetchClientIdentity}
            clientIdentity={clientIdentity}
            setShowClientDetail={setShowClientDetail}
            instanceId={instanceId} />
        </Card.Body>
      </Card>
      <ClientEditionModal
        setShow={setShowClientModal}
        show={showClientModal}
        fetchClientIdentity={fetchClientIdentity}
        source={clientIdentityRef.current}
        instanceId={instanceId}
        configurationOpenId={configurationOpenId}
        instanceToken={instanceToken} />
      <ClientIdentityDetails
        show={showClientDetail}
        setShow={setShowClientDetail}
        clientDetail={clientIdentityRef.current} />
    </>
  );
};

export default ClientCard;
