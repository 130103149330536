import React, { FC } from 'react';
import { Form } from 'react-bootstrap'
import { Account } from '../../interface/account';
import { tradAccountForm } from './DelegationFormKeyTranslation';

interface AccountFormProps {
  account: Account,
  onChangeActionName: Function,
  onContactPrincipalChanged: Function,
  onEmailPrincipalChanged: Function
}
const AccountForm: FC<AccountFormProps> = (props) => {
  const { account, onChangeActionName, onContactPrincipalChanged, onEmailPrincipalChanged } = props;
  const { tradAccountFormName, tradAccountFormPlaceholder, tradAccountFormPrincipalContact, tradAccountFormPrimaryContact, tradAccountFormEmail, tradAccountFormPlaceholderEmail } = tradAccountForm();
  const email = account.principalEmail.trim();

  return (
    <>
      {
        <Form>
          <Form.Group className='mb-3'>
            <Form.Label> {tradAccountFormName}</Form.Label>
            <Form.Control
              onChange={(event) => onChangeActionName(event)}
              type="text"
              placeholder={tradAccountFormPlaceholder}
              value={account.name} />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label> {tradAccountFormPrincipalContact}</Form.Label>
            <Form.Control
              onChange={(event) => onContactPrincipalChanged(event)}
              name="contactPrincipal"
              value={account.principalContact}
              type="text"
              placeholder={tradAccountFormPrimaryContact} />
          </Form.Group>
          <Form.Group>
            <Form.Label> {tradAccountFormEmail}</Form.Label>
            <Form.Control
              onChange={(event) => onEmailPrincipalChanged(event)}
              type="text"
              value={email}
              placeholder={tradAccountFormPlaceholderEmail} />
          </Form.Group>
        </Form>
      }
    </>
  )
}
export default AccountForm;
