/* eslint-disable no-unused-vars */
import { t } from 'i18next';
import { translatedText } from '../../../i18n/config';

export function tradToaster() {
    const tradToasterSuccess = translatedText('messages.success', t);
    const tradToasterSuccessCreate = translatedText('instance.create.success', t);
    const tradToasterError = translatedText('messages.error', t);
    const tradToasterCreationError = translatedText('account.instance.error', t);
    const tradToasterEmptyField = translatedText('instance.create.error_empty_field', t);
    const tradToasterDeleteSuccess = translatedText('instance.delete.success', t);
    const tradToasterUpdateAppSuccess = translatedText('application.update.success', t);
    const tradToasterUpdateAppError = translatedText('application.update.error', t);
    const tradToasterCreationAppSuccess = translatedText('application.create.success', t);
    const tradToasterCreationAppError = translatedText('application.create.error', t);
    const tradToasterDeleteAppSuccess = translatedText('application.delete.success', t);
    const tradToasterDeleteAppError = translatedText('application.delete.error', t);
    const tradToasterUpdateSuccess = translatedText('instance.update.success', t);
    return { tradToasterSuccess, tradToasterSuccessCreate, tradToasterError, tradToasterCreationError, tradToasterEmptyField, tradToasterDeleteSuccess, tradToasterUpdateAppSuccess, tradToasterUpdateAppError, tradToasterCreationAppSuccess, tradToasterCreationAppError, tradToasterDeleteAppSuccess, tradToasterDeleteAppError, tradToasterUpdateSuccess };
}

export function tradCreationForm() {
    const tradCreationFormNextStep = translatedText('next_step', t);
    const tradCreationFormValidation = translatedText('add_instance.validation', t);
    const tradCreationFormTitle = translatedText('add_instance.title_modal', t);
    const tradCreationFormAddApp = translatedText('add_instance.application', t);
    const tradCreationFormCancelAdd = translatedText('add_account.cancel_button', t);
    const tradCreationFormPreviousStep = translatedText('previous_step', t);
    const tradCreationFormChooseAccountName = translatedText('instance.choose_account_name', t);
    const tradCreationFormChooseAccount = translatedText('license.form.choose_account', t);
    const tradCreationFormInstanceName = translatedText('instance.instance_name', t);
    const tradCreationFormHostingType = translatedText('instance.choose_hosting_type', t);
    const tradCreationFormChooseState = translatedText('choose_state', t);
    const tradCreationFormChooseInstanceType = translatedText('instance.choose_instance_type', t);
    return { tradCreationFormNextStep, tradCreationFormValidation, tradCreationFormTitle, tradCreationFormAddApp, tradCreationFormCancelAdd, tradCreationFormPreviousStep, tradCreationFormChooseAccountName, tradCreationFormChooseAccount, tradCreationFormInstanceName, tradCreationFormHostingType, tradCreationFormChooseState, tradCreationFormChooseInstanceType };
}

export enum urlNames {
    teiaCore = 'teia_core',
    teiaTwin = 'teia_twin',
    teiaEngine = 'teia_engine',
    teiaConnector = 'teia_connector',
    teiaWorkflow = 'teia_workflow'
}

export function tradStateInstance() {
    const tradStateInstanceSaas = translatedText('saas', t);
    const tradStateInstanceOnPremise = translatedText('onPremise', t);
    const tradStateInstanceStarted = translatedText('instances_start', t);
    const tradStateInstanceStopped = translatedText('instances_stop', t);
    const tradStateInstanceArchived = translatedText('instances_archived', t);
    return { tradStateInstanceSaas, tradStateInstanceOnPremise, tradStateInstanceStarted, tradStateInstanceStopped, tradStateInstanceArchived };
}

export function tradinstanceDelete() {
    const tradinstanceDeleteCancel = translatedText('cancel', t);
    const tradinstanceDeleteConfirm = translatedText('instance.delete.confirm', t);
    const tradinstanceDeleteTitle = translatedText('Confirmation', t);
    const tradinstanceDeleteMessage = translatedText('instance.delete.confirmation_message', t);
    return { tradinstanceDeleteCancel, tradinstanceDeleteConfirm, tradinstanceDeleteTitle, tradinstanceDeleteMessage };
}

export function tradUpdateForm() {
    const tradUpdateFormNextStep = translatedText('next_step', t);
    const tradUpdateFormValidation = translatedText('add_instance.validation', t);
    const tradUpdateFormTitle = translatedText('add_instance.title_modal', t);
    const tradUpdateFormAddApp = translatedText('add_instance.application', t);
    const tradUpdateFormCancelAdd = translatedText('add_account.cancel_button', t);
    const tradUpdateFormPreviousStep = translatedText('previous_step', t);
    const tradUpdateFormChooseAccountName = translatedText('instance.choose_account_name', t);
    const tradUpdateFormChoose = translatedText('license.form.choose_account', t);
    const tradUpdateFormInstanceName = translatedText('instance.instance_name', t);
    const tradUpdateFormHostingType = translatedText('instance.choose_hosting_type', t);
    const tradUpdateFormChooseState = translatedText('choose_state', t);
    const tradUpdateFormChooseInstanceType = translatedText('instance.choose_instance_type', t);
    return { tradUpdateFormNextStep, tradUpdateFormValidation, tradUpdateFormTitle, tradUpdateFormAddApp, tradUpdateFormCancelAdd, tradUpdateFormPreviousStep, tradUpdateFormChooseAccountName, tradUpdateFormChoose, tradUpdateFormInstanceName, tradUpdateFormHostingType, tradUpdateFormChooseState, tradUpdateFormChooseInstanceType };
}
