import React from 'react'
import { Link } from 'react-router-dom';
import { tradError403 } from '../ErrorKeyTranslation';
import CorporateManagementLogo from '../../../pages/Home/CorporateManagementLogo.svg'

const Error403 = () => {
  const { tradError403Title, tradError403Description, tradError403Logout, tradHomeImageDesc } = tradError403();
  return (
    <>
      <Link to="/"><img style={{ height: '50px' }} src="Logo-stereograph.png"></img></Link>

      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-lg-4">
            <div className="text-center">
              <img style={{ width: 400, paddingLeft: 30 }} src={CorporateManagementLogo} alt={tradHomeImageDesc} />
              <h1 className="text-error mt-4">403</h1>
              <h4 className="text-uppercase text-primary mt-3">{tradError403Title}</h4>
              <p className="text-muted mt-3">{tradError403Description}</p>
              <button className="btn btn-primary align-self-center" onClick={() => window.location.replace(`${process.env.REACT_APP_IDENTITY_URL}/Identity/Account/Logout`)}>
                {tradError403Logout}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Error403;
