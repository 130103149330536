import React from 'react';

import { resetToken } from '../../utils/authentication';

export const LogoutCallback = () => {
  resetToken();
  window.location.href = `${process.env.REACT_APP_BASE_URL}`;

  return (
    <>
    </>
  );
}
