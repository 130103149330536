import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import InstancesTable from '../../components/Tables/InstancesTable/InstancesTable';
import { instanceApi } from '../../api/instanceApi';

const Instances = () => {
  const [instances, setInstances] = useState<Array<any>>([]);
  const [itemsPerPage, setItemsPerPage] = useState<number>(5);

  useEffect(() => {
    const fetchInstances = async () => {
      try {
        const result = await instanceApi.getAll();
        setInstances(result ?? [])
      } catch (err) {
        console.log(err);
      }
    }
    fetchInstances();
  }, [])

  const refreshInstances = async () => {
    const result = await instanceApi.getAll();
    setInstances(result ?? []);
  };

  return (
    <Container>
      <Row>
        <Col lg={12} md={12}>
          <InstancesTable instances={instances} itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} refreshInstance={refreshInstances} />
        </Col >
      </Row>
    </Container>
  )
}

export default Instances;
