import { CustomModal } from '@stereograph/storybook/dist/components/CustomModal/CustomModal';
import React, { FC, useContext } from 'react';
import { delegationAPi } from '../../../api/delegationApi';
import { ToasterContext } from '../../../pages/App/App';
import { tradToaster, tradDelete } from './DelegationkeyTranslation';

interface DeleteDelegationProps {
    instanceId: number;
    delegationId: number;
    show: boolean;
    setShow: Function;
    refreshDelegations: boolean;
    setRefreshDelegations: Function;
}
const DeleteDelegationModal: FC<DeleteDelegationProps> = (props) => {
    const { tradToasterSuccess, tradToasterDelegationDelete } = tradToaster();
    const { tradDeleteCancel, tradDeleteAction, tradDeleteTitle, tradinstanceDeleteMessage } = tradDelete();
    const { show, setShow, instanceId, delegationId, refreshDelegations, setRefreshDelegations } = props;
    const { pushToast } = useContext(ToasterContext);
    const deleteAccount = async () => {
        const result = await delegationAPi.delete(instanceId, delegationId);
        if (result?.status === 204) {
            pushToast({
                type: 'success',
                title: tradToasterSuccess,
                message: tradToasterDelegationDelete,
                autoHide: true
            });
        }
        setRefreshDelegations(!refreshDelegations);
        setShow(false);
    }

    return (
        <CustomModal show={show} setShow={setShow}
            onAction={deleteAccount} cancelButton={tradDeleteCancel}
            actionName={tradDeleteAction} type="danger" title={tradDeleteTitle}>
            {tradinstanceDeleteMessage}
        </CustomModal>
    )
}

export default DeleteDelegationModal;
