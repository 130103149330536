import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

export interface FooterProps {
  className: string,
  text: string,
  class: string,
  version: string
}

export default function Footer(props:FooterProps) {
  return (
    <footer className="footer bg-white shadow align-self-end py-3 px-xl-5 w-100">
      <Container fluid>
        <Row>
          <Col md={6} className={props.className}>
            <p className="mb-2 mb-md-0">{props.text}</p>
          </Col>
          <Col md={6} className={props.class}>
            <p className="mb-0">{props.version}</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
