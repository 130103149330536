import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import en from './en/translations.json';
import fr from './fr/translations.json';
import { initReactI18next } from 'react-i18next';
import { useMemo } from 'react';

export const resources = {
  en: {
    translation: en
  },
  fr: {
    translation: fr
  }
} as const;

i18n.use(detector)
  .use(initReactI18next)
  .init({
    debug: true,
    returnObjects: true,
    fallbackLng: 'en',
    saveMissing: true,
    resources
  });

export function translatedText(translationKey: string, t) {
  return useMemo(() => {
    return t(translationKey);
  }, [t, translationKey]);
}
