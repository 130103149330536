import React from 'react';
import { Form } from 'react-bootstrap';
import { t } from 'i18next';
import { Instance } from '../../../../models/instance';

function UpdateUrlApplication(name: string, nbr: number, currentInstance: Instance, onChangeUrl: (event: any) => void) {
    return <Form.Group className="mb-3">
        <div className="fs-4">{name}</div>
        <Form.Label className="text-uppercase">{t('instance.url')}</Form.Label>
        <Form.Control type="text" defaultValue={currentInstance.applications?.find(app => app.type === nbr)?.url ?? ''} onChange={(event) => onChangeUrl(event)} placeholder="https://...." />
    </Form.Group>;
}

export default UpdateUrlApplication
