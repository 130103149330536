/* eslint-disable no-unused-vars */
import { t } from 'i18next';
import { translatedText } from '../../../i18n/config';

export function tradHeader() {
    const tradHeaderAccount = translatedText('account.account', t);
    const tradHeaderPrimaryContact = translatedText('account.primary_contact', t);
    const tradHeaderEmailContact = translatedText('account.email_contact', t);
    const tradHeaderDate = translatedText('account.generated_on', t);
    const tradHeaderLabel = translatedText('actions.label', t);
    const tradHeaderUpdate = translatedText('update', t);
    const tradHeaderDelete = translatedText('delete', t);
    return { tradHeaderAccount, tradHeaderPrimaryContact, tradHeaderEmailContact, tradHeaderDate, tradHeaderLabel, tradHeaderUpdate, tradHeaderDelete };
}

export function tradTableForm() {
    const tradTableFormTitle = translatedText('account.title', t);
    const tradTableFormCsv = translatedText('account.download_csv_file', t);
    const tradTableFormAddCustomers = translatedText('account.add_new_customers', t);
    const tradTableFormPlaceholder = translatedText('placeholder', t);
    const tradTableFormSortPage = translatedText('sort_per_page', t);
    const tradTableFormDate = translatedText('date.format', t);
    const tradTableFormSearch = translatedText('placeholder.search', t);
    return { tradTableFormTitle, tradTableFormCsv, tradTableFormAddCustomers, tradTableFormPlaceholder, tradTableFormSortPage, tradTableFormDate, tradTableFormSearch };
}
