import axios from 'axios';
import React, { FC, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getCodeChallenge, getCodeVerifier, resetCode, setToken } from '../../utils/authentication';

const AuthenticationCallback: FC = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_IDENTITY_URL}/connect/authorize/callback?response_type=code&client_id=corporatemanagement.client&scope=corporatemanagement.ro corporatemanagement.wo profile openid isCMUser IdentityServerApi email&redirect_uri=${process.env.REACT_APP_BASE_URL}/authentication/callback&code_challenge=${getCodeChallenge()}&code_challenge_method=S256`)
      .then(() => {
        const body = new URLSearchParams();
        body.append('grant_type', 'authorization_code');
        body.append('code', code as string);
        body.append('redirect_uri', `${process.env.REACT_APP_BASE_URL}/authentication/callback`);
        body.append('code_verifier', getCodeVerifier());
        body.append('client_id', 'corporatemanagement.client');
        body.append('scope', 'corporatemanagement.ro corporatemanagement.wo openid profile isCMUser IdentityServerApi');
        body.append('client_secret', `${process.env.REACT_APP_IDENTITY_SECRET}`);
        axios.post(`${process.env.REACT_APP_IDENTITY_URL}/connect/token`, body, {
          headers: {
            'content-type': 'application/x-www-form-urlencoded'
          }
        }).then(res => {
          setToken(res.data.access_token, res.data.id_token, res.data.expires_in);
          resetCode();
          window.location.href = `${process.env.REACT_APP_BASE_URL}/account`;
        }).catch(err => {
          console.log(err);
          window.location.href = `${process.env.REACT_APP_BASE_URL}`;
        });
      })
  }, [])

  return (
    <>
    </>
  );
}

export default AuthenticationCallback;
