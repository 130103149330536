/* eslint-disable no-unused-vars */
import { t } from 'i18next';
import { translatedText } from '../../i18n/config';

export function tradClientIdentity() {
    const tradClientIdentityTitle = translatedText('user.clientTitle', t);
    const tradClientIdentityAddClient = translatedText('client.add_new_client_identity', t);
    return { tradClientIdentityTitle, tradClientIdentityAddClient };
}

export function tradDashboardInstance() {
    const tradDashboardInstanceDate = translatedText('date.format', t);
    const tradDashboardInstanceRecently = translatedText('dashboard.recentely_instances', t);
    return { tradDashboardInstanceDate, tradDashboardInstanceRecently };
}

export function tradDashboardLicense() {
    const tradDashboardLicenseDate = translatedText('date.format', t);
    const tradDashboardLicenseExpiredSoon = translatedText('dashboard.expired_soon_licenses', t);
    return { tradDashboardLicenseDate, tradDashboardLicenseExpiredSoon };
}

export function tradLicense() {
    const tradLicenseInfo = translatedText('license.informations', t);
    const tradLicenseLabel = translatedText('actions.label', t);
    const tradLicenseAddLicense = translatedText('license.add_new_licenses', t);
    const tradLicenseHistory = translatedText('history', t);
    const tradLicenseDateCreation = translatedText('license.card.created_at', t);
    const tradLicenseProjectNbr = translatedText('license.card.project_numebr', t);
    const tradLicenseUserNbr = translatedText('license.card.users_number', t);
    const tradLicenseDateExp = translatedText('license.card.expiration_date', t);
    return { tradLicenseInfo, tradLicenseLabel, tradLicenseAddLicense, tradLicenseHistory, tradLicenseDateCreation, tradLicenseProjectNbr, tradLicenseUserNbr, tradLicenseDateExp };
}

export function tradInstanceToaster() {
    const tradInstanceToasterSuccess = translatedText('messages.success', t);
    const tradInstanceToasterUpdate = translatedText('application.update.success', t);
    const tradInstanceToasterCreate = translatedText('application.create.success', t);
    const tradInstanceToasterDelete = translatedText('application.delete.success', t);
    const tradInstanceToasterinstanceUpdate = translatedText('instance.update.success', t);
    return { tradInstanceToasterSuccess, tradInstanceToasterUpdate, tradInstanceToasterCreate, tradInstanceToasterDelete, tradInstanceToasterinstanceUpdate };
}

export function tradInstanceLabel() {
    const tradInstanceLabelInfo = translatedText('instances.informations', t);
    const tradInstanceLabelInstanceToken = translatedText('instances.instanceToken', t);
    const tradInstanceLabelName = translatedText('instance.name', t);
    const tradInstanceLabelType = translatedText('instance.type', t);
    const tradInstanceLabelState = translatedText('instance.state', t);
    const tradInstanceLabelEnvironment = translatedText('instances.environment', t);
    const tradInstanceLabelComments = translatedText('comments', t);
    return { tradInstanceLabelInfo, tradInstanceLabelInstanceToken, tradInstanceLabelName, tradInstanceLabelType, tradInstanceLabelState, tradInstanceLabelEnvironment, tradInstanceLabelComments };
}

export function tradInstanceUrl() {
    const tradInstanceUrlTeiaCore = translatedText('instance.url_teia_core', t);
    const tradInstanceUrlTeiaTwin = translatedText('instance.url_teia_twin', t);
    const tradInstanceUrlTeiaEngine = translatedText('instance.url_teia_engine', t);
    const tradInstanceUrlTeiaConnector = translatedText('instance.url_teia_connector', t);
    const tradInstanceUrlTeiaWorkflow = translatedText('instance.url_teia_workflow', t);
    return { tradInstanceUrlTeiaCore, tradInstanceUrlTeiaTwin, tradInstanceUrlTeiaEngine, tradInstanceUrlTeiaConnector, tradInstanceUrlTeiaWorkflow };
}

export function tradInstanceButton() {
    const tradInstanceButtonSaas = translatedText('saas', t);
    const tradInstanceButtonOnPremise = translatedText('onPremise', t);
    const tradInstanceButtonStart = translatedText('instances_start', t);
    const tradInstanceButtonStop = translatedText('instances_stop', t);
    const tradInstanceButtonArchived = translatedText('instances_archived', t);
    const tradInstanceButtonDemo = translatedText('instance.instance_type.demo', t);
    const tradInstanceButtonIntegration = translatedText('instance.instance_type.integration', t);
    const tradInstanceButtonPreProd = translatedText('instance.instance_type.preprod', t);
    const tradInstanceButtonProd = translatedText('instance.instance_type.production', t);
    const tradInstanceButtonCancel = translatedText('cancel', t);
    const tradInstanceButtonSave = translatedText('save', t);
    return { tradInstanceButtonSaas, tradInstanceButtonOnPremise, tradInstanceButtonStart, tradInstanceButtonStop, tradInstanceButtonArchived, tradInstanceButtonDemo, tradInstanceButtonIntegration, tradInstanceButtonPreProd, tradInstanceButtonProd, tradInstanceButtonCancel, tradInstanceButtonSave };
}
