import React, {
  useState
} from 'react';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import Footer from '../Footer/Footer';

const Layout = (pageProps:any) => {
  const [sidebarShrink, setSidebarShrink] = useState(false);

  return (
    <div className={pageProps.className}>
      {!pageProps.hideHeader && <Header setSidebarShrink={setSidebarShrink} sidebarShrink={sidebarShrink} {...pageProps}/>}

      <div className="d-flex align-items-stretch" >
        {!pageProps.hideSidebar && <Sidebar data={pageProps.routes} sidebarShrink={sidebarShrink} />}
        <div className={`page-holder ${pageProps.pageHolderClass
          ? pageProps.pageHolderClass
          : 'bg-gray-100'}`} >
          {pageProps.children}
          {!pageProps.hideFooter && <Footer {...pageProps.footerProps} />}
        </div>
      </div>
    </div>
  )
}

export default Layout
