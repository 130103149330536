import { t } from 'i18next';
import { License } from '../interface/License/License';
import { LicenseDTO } from '../interface/License/LicenseDTO';
import axios from '../middleware/requestInterceptor';

export const licenseApi = {
  async getAll() {
      const result = await axios.get('/licenses');
       const arrayInstance:Array<LicenseDTO> = [];
           result.data.map((license:LicenseDTO) => {
             const licenseCreationDate:Date = new Date(license.licenseCreationDate);
             const licenseExpiratedAt:Date = new Date(license.licenseExpiratedAt);
             const licenseExpirationDate:Date = new Date(license.licenseExpirationDate);
             return arrayInstance.push({ ...license, creationLicenseDate: licenseCreationDate.toLocaleString(t('date.format')), expirationLicenseAt: licenseExpiratedAt.toLocaleString(t('date.format')), expirationLicenseDate: licenseExpirationDate.toLocaleString(t('date.format')) })
           })
      return arrayInstance;
  },
  async getAllByInstanceId(instanceId:number) {
      const result = await axios.get(`/licenses?instanceId=${instanceId}`);
      return result.data;
  },
  async getLicenseById(licenseId:number) {
      const result = await axios.get(`/licenses/${licenseId}`);
      return result.data;
  },
  async create(license: License) {
    const timestamp = Date.parse(license.expirationDate);
    if (isNaN(timestamp) === false) {
        const result = await axios.post('/licenses', {
          instanceId: license.instanceId,
          usersCount: license.usersCount,
          usage: license.type,
          projectsCount: license.projectsCount,
          expirationDate: new Date(timestamp)
        });
        return result;
    } else {
      console.log('invalid date');
    }
  },
  async delete(license: License) {
      const result = await axios.delete(`/licenses/${license.id}`);
      return result;
  }
}
