import { t } from 'i18next';
import { useContext } from 'react'
import { ToasterContext } from '../pages/App/App';

type CopyFn = (text: string) => Promise<boolean> // Return success

function useCopyToClipboard(): [CopyFn] {
    const { pushToast } = useContext(ToasterContext);

  const copy: CopyFn = async text => {
    if (!navigator?.clipboard) {
      pushToast({
        type: 'error',
        title: t('messages.error'),
        message: t('instance.clipboard.error_navigator'),
        autoHide: true
      })
      return false
    }

    try {
      await navigator.clipboard.writeText(text)
      pushToast({
        type: 'success',
        title: t('messages.success'),
        message: t('instance.clipboard.success'),
        autoHide: true
      })
      return true
    } catch (error) {
      pushToast({
        type: 'error',
        title: t('messages.error'),
        message: t('instance.clipboard.error'),
        autoHide: true
      })
      return false
    }
  }

  return [copy]
}

export default useCopyToClipboard;
