import React, { FC, createContext, useState } from 'react'
import './App.scss';
import '../../i18n/config'
import { Route, Routes } from 'react-router-dom'
import Home from '../Home/Home';
import CorporateLayout from '../CorporateLayout/CorporateLayout';
import Account from '../Account/Account';
import AuthenticationCallback from '../AuthenticationCallback/AuthenticationCallback';
import { ToasterInfo, ToasterProps } from '../../components/Toaster/Toaster';
import { ToastContainer } from 'react-bootstrap';
import License from '../License/License';
import Instances from '../Instances/Instances';
import InstanceDetails from '../Instances/InstanceDetails';
import Dashboard from '../Dashboard/Dashboard';
import Error403 from '../../components/Errors/Error403/Error403';
import Error404 from '../../components/Errors/Error404/Error404';
import { LogoutCallback } from '../LogoutCallback/LogoutCallback';

interface ContextToaster {
  pushToast: (options: ToasterProps) => void,
  toasts: Array<ToasterProps>
}

export const ToasterContext = createContext<ContextToaster>({
  pushToast: (options) => { },
  toasts: []
});

const App: FC = () => {
  const [toasts, setToasts] = useState<Array<ToasterProps>>([]);

  const removeToast = (index: number) => {
    const toastsCopy = [...toasts];
    toastsCopy.splice(index, 1);
    setToasts(toastsCopy);
  }

  const pushToast = (options: ToasterProps) => {
    const isDuplicate = toasts.some((toast) => options.message === toast.message && options.type === 'error');

    if (!isDuplicate) {
      const updatedToasts = [...toasts, options];
      setToasts(updatedToasts);
    }
  };

  return (
    <>
      <ToasterContext.Provider value={{ toasts, pushToast }}>
        <ToastContainer id="toaster" position="top-end" containerPosition='fixed'>
          {toasts.map((toast, index) => {
            return (
              <ToasterInfo key={`toast-${index}`}
                type={toast.type}
                title={toast.title}
                message={toast.message}
                autoHide={toast.autoHide}
                onClose={() => removeToast(index)} />
            )
          })}
        </ToastContainer>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/forbidden" element={<Error403 />} />
          <Route path="/" element={<CorporateLayout />} >
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="account" element={<Account />} />
            <Route path="license" element={<License />} />
            <Route path="instances" element={<Instances />} />
            <Route path="instances/:instanceId" element={<InstanceDetails />} />
            <Route path="*" element={<Error404 />} />
          </Route>
          <Route path="/authentication/callback" element={<AuthenticationCallback />} />
          <Route path="/logout-callback" element={<LogoutCallback />} />
        </Routes>
      </ToasterContext.Provider>
    </>
  )
}
export default App;
