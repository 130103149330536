import React, { FC, useState, useEffect, useMemo, ChangeEvent } from 'react';
import { Button, Card, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { LicenseDTO } from '../../../interface/License/LicenseDTO';
import { DataTable, GenericFilter } from '@stereograph/storybook';
import { licenseApi } from '../../../api/licenseApi';
import CreateLicenseModal from '../../Modals/License/CreateLicenseModal';
import DeleteLicenseModal from '../../Modals/License/DeleteLicenseModal';
import { License } from '../../../interface/License/License';
import { accountApi } from '../../../api/accountApi';
import { CellProps } from 'react-table';
import useCopyToClipboard from '../../../hooks/CopyToClipboard';
// eslint-disable-next-line no-unused-vars
import Select, { ActionMeta, MultiValue, OptionProps, Options } from 'react-select';
import { Option } from '../../../interface/option';
import { tradHeader, tradLabel, tradState, tradTable, tradType } from './LicenceTableKeyTranslation';

import '../Table.css';
import { saasBadgeStyle, onPremiseBadgeStyle, validBadgeStyle, expiredBadgeStyle, demoBadgeStyle, proddBadgeStyle, preProddBadgeStyle, sdkBadgeStyle, expiredSoonBadgeStyle } from '../BadgesStyle';

const ActionType = {
  CREATE: 1,
  UPDATE: 2,
  DELETE: 3
};

interface FilteredLicenses {
  accounts: string[];
  instance: string[];
  search: string;
  state: string[];
}

const LicenseTable: FC = () => {
  const { tradHeaderAccount, tradHeaderInstance, tradHeaderType, tradHeaderInstanceUsage, tradHeaderState, tradHeaderLimites, tradHeaderExpiration, tradHeaderCreated, tradHeaderLabel } = tradHeader();
  const { tradStateValid, tradStateOk, tradStateExpiredSoon, tradStateExpired } = tradState();
  const { tradTypeProd, tradTypePreProd, tradTypeSdk, tradTypeDemo } = tradType();
  const { tradLabelDelete, tradLabelCopy } = tradLabel();
  const { tradTableTitle, tradTableAddLicense, tradTableSortPage, tradTableDate, tradTableSearch, tradTableInstanceToken, tradTableAccount, tradTableInstance, tradTableLicenseState } = tradTable();
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const [licensesSource, setLicensesSource] = useState<Array<LicenseDTO>>([]);
  const [showModalLicenses, setShowModalLicenses] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<Array<any>>([]);
  const [actionType, setActionType] = useState<any>(ActionType.CREATE);
  const [license, setLicense] = useState<License>({
    id: 0,
    instanceId: 0,
    expirationDate: '',
    projectsCount: 0,
    usersCount: 0,
    type: 0
  });
  const [filteredLicenses, setFilteredLicenses] = useState<FilteredLicenses>({
    search: '',
    accounts: [],
    instance: [],
    state: []
  });
  const [copy] = useCopyToClipboard();

  useEffect(() => {
    const fetchLicenses = async () => {
      const result = await licenseApi.getAll();
      setLicensesSource(result ?? []);
    };
    fetchLicenses();
  }, [refreshData]);

  useEffect(() => {
    const fetchAccounts = async () => {
      const result = await accountApi.getAll();
      setAccounts(result ?? []);
    };
    fetchAccounts();
  }, []);

  const createLicense = () => {
    setActionType(ActionType.CREATE);
    setShowModalLicenses(true);
  };

  const deleteLicense = async (licenseDTO: LicenseDTO) => {
    setActionType(ActionType.DELETE);
    setLicense({
      id: licenseDTO.licenseId,
      instanceId: 0,
      expirationDate: '',
      projectsCount: 0,
      usersCount: 0,
      type: 0
    });
    setShowModalLicenses(true);
  };

  const handleFilter = (event: ChangeEvent) => {
    const value = (event.target as HTMLInputElement).value;
    setFilteredLicenses({ ...filteredLicenses, search: value });
  };

  const columnsDefinition = useMemo(
    () => [
      {
        Header: tradHeaderAccount,
        accessor: 'accountName',
        Cell: ({ cell }: CellProps<License>) => (
          <OverlayTrigger placement="top" delay={{ show: 250, hide: 300 }} overlay={<Tooltip>{cell.row.original.accountName}</Tooltip>}>
            <span className="text-truncate" style={{ maxWidth: '120px', display: 'inline-block', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {cell.row.original.accountName}
            </span>
          </OverlayTrigger>
        )
      },
      {
        Header: tradHeaderInstance,
        accessor: 'instanceName',
        Cell: ({ cell }: CellProps<License>) => (
          <OverlayTrigger placement="top" delay={{ show: 250, hide: 300 }} overlay={<Tooltip>{cell.row.original.instanceName}</Tooltip>}>
            <span className="text-truncate" style={{ maxWidth: '120px', display: 'inline-block', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {cell.row.original.instanceName}
            </span>
          </OverlayTrigger>
        )
      },
      {
        Header: tradHeaderType,
        accessor: 'instanceType',
        Cell: ({ cell }: CellProps<any>) => {
          switch (cell.row.original.instanceType) {
            case 'SAAS':
              return (
                <span className={saasBadgeStyle}>
                  {cell.row.original.instanceType}
                </span>
              );
            case 'ONPREMISE':
              return (
                <span className={onPremiseBadgeStyle}>
                  {cell.row.original.instanceType}
                </span>
              );
            default:
              break;
          }
        }
      },
      {
        Header: tradHeaderInstanceUsage,
        accessor: 'usage',
        Cell: ({ cell }: CellProps<any>) => {
          switch (cell.row.original.usage) {
            case 'PROD':
              return (
                <span className={proddBadgeStyle}>
                  {tradTypeProd}
                </span>
              );
            case 'PREPROD':
              return (
                <span className={preProddBadgeStyle}>
                  {tradTypePreProd}
                </span>
              );
            case 'SDK':
              return (
                <span className={sdkBadgeStyle}>
                  {tradTypeSdk}
                </span>
              );
            case 'DEMO':
              return (
                <span className={demoBadgeStyle}>
                  {tradTypeDemo}
                </span>
              );
            default:
              break;
          }
        }
      },
      {
        Header: tradHeaderState,
        accessor: 'licenseState',
        Cell: ({ cell }: CellProps<any>) => {
          switch (cell.row.original.licenseState) {
            case 'VALID':
              return (
                <span className={validBadgeStyle}>
                  {tradStateValid}
                </span>
              );
            case 'OK':
              return (
                <span className={validBadgeStyle}>
                  {tradStateOk}
                </span>
              );
            case 'EXPIREDSOON':
              return (
                <span className={expiredSoonBadgeStyle}>
                  {tradStateExpiredSoon}
                </span>
              );
            case 'EXPIRED':
              return (
                <span className={expiredBadgeStyle}>
                  {tradStateExpired}
                </span>
              );
            default:
              break;
          }
        }
      },
      {
        Header: tradHeaderLimites,
        accessor: '',
        Cell: ({ cell }: CellProps<any>) => (
          <>
            <span className="d-block">{cell.row.original.licenseProjectsCount} Projects</span>
            <span className="d-block">{cell.row.original.licenseUsersCount} Users</span>
            <span className="d-block">{cell.row.original.expirationLicenseDate.split(' ')[0]}</span>
          </>
        )
      },
      {
        Header: tradHeaderExpiration,
        accessor: 'expirationLicenseDate',
        Cell: ({ cell }: CellProps<any>) => (
          <span className="d-block">{cell.row.original.expirationLicenseDate.split(' ')[0]}</span>
        )
      },
      {
        Header: tradHeaderCreated,
        accessor: 'creationLicenseDate',
        Cell: ({ cell }: CellProps<any>) => {
          return cell.row.original.creationLicenseDate.split(' ')[0];
        }
      },
      {
        Header: tradHeaderLabel,
        accessor: '',
        Cell: ({ cell }: CellProps<any>) => (
          <>
            <Dropdown>
              <Dropdown.Toggle variant="primary" size="sm">
                {tradHeaderLabel}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    deleteLicense(cell.row.original);
                  }}>
                  {tradLabelDelete}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    copy(cell.row.original.licenseSerial);
                  }}>
                  {tradLabelCopy}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        )
      }
    ],
    [licensesSource]
  );

  const onFilteredChangeCustom = (newValue: MultiValue<Option>, action: ActionMeta<Option>, accessor: string) => {
    if (action.action === 'select-option') {
      setFilteredLicenses({ ...filteredLicenses, [accessor]: newValue.map((value) => value.value) });
    }
    if (action.action === 'remove-value') {
      setFilteredLicenses({ ...filteredLicenses, [accessor]: newValue.map((value) => value.value) });
    }
  };

  const customLabel = (option: Option) => {
    switch (option.value) {
      case 'EXPIRED':
        return tradStateExpired;
      case 'EXPIREDSOON':
        return tradStateExpiredSoon;
      case 'VALID':
        return tradStateValid;
      default:
        return option.label;
    }
  };

  const selectOption = (accessor: string) => {
    if (accessor === 'licenseState') {
      return licensesSource
        .map((licence) => ({
          label: customLabel({ value: licence[accessor], label: licence[accessor] }),
          value: licence[accessor]
        }))
        .filter((option, index, self) => index === self.findIndex((t) => t.label === option.label && t.value === option.value))
        .sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });
    }

    if (accessor === 'instanceName' && filteredLicenses.accounts.length) {
      const objUnique: Array<Option> = [];
      const filteredLicensesAccount = licensesSource.filter((licence) => filteredLicenses.accounts.includes(licence.accountName));
      const setInstanceName = new Set<string>();
      filteredLicensesAccount.forEach((t) => {
        if (!setInstanceName.has(t.instanceName)) {
          setInstanceName.add(t.instanceName);
          objUnique.push({ label: t.instanceName, value: t.instanceName });
        }
      });
      return objUnique.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
    }

    return licensesSource
      .map((licence) => ({
        label: licence[accessor],
        value: licence[accessor]
      }))
      .filter((option, index, self) => index === self.findIndex((t) => t.label === option.label && t.value === option.value))
      .sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
  };

  const tableItemsFiltered = licensesSource.filter((item) => {
    if (filteredLicenses.search && !item.instanceToken.includes(filteredLicenses.search)) {
      return false;
    }
    if (filteredLicenses.accounts.length && !filteredLicenses.accounts.includes(item.accountName)) {
      return false;
    }
    if (filteredLicenses.instance.length && !filteredLicenses.instance.includes(item.instanceName)) {
      return false;
    }
    if (filteredLicenses.state.length && !filteredLicenses.state.includes(item.licenseState)) {
      return false;
    }
    return true;
  });

  return (
    <>
      {actionType === ActionType.CREATE && (
        <CreateLicenseModal
          accounts={accounts}
          show={showModalLicenses}
          setShow={setShowModalLicenses}
          refreshData={refreshData}
          setRefreshData={setRefreshData}
        />
      )}
      {actionType === ActionType.DELETE && (
        <DeleteLicenseModal
          license={license}
          show={showModalLicenses}
          setShow={setShowModalLicenses}
          refreshData={refreshData}
          setRefreshData={setRefreshData}
        />
      )}

      <Card className="card-table mb-4">
        <Card.Header className="p-4 d-flex justify-content-between align-items-center">
          <h4 className="card-heading">{tradTableTitle}</h4>
          <Button
            variant="outline-primary"
            onClick={() => {
              createLicense();
            }}>
            {' '}{tradTableAddLicense}{' '}
          </Button>
        </Card.Header>
        <Card.Body>
          <div className='filter'>
            <DataTable
              columns={columnsDefinition}
              items={tableItemsFiltered}
              labelPage={tradTableSortPage}
              lang={tradTableDate}>
              <h6>{tradTableSearch}</h6>
              <GenericFilter placeholder={tradTableInstanceToken} handleChange={handleFilter} />
              <Select
                className="d-inline-block ms-4 w-196"
                placeholder={tradTableAccount}
                options={selectOption('accountName')}
                isMulti
                isClearable={false}
                onChange={(newValue, action) => onFilteredChangeCustom(newValue, action, 'accounts')}
              />
              <Select
                className="d-inline-block ms-4 w-196"
                placeholder={tradTableInstance}
                options={selectOption('instanceName')}
                isMulti
                isClearable={false}
                onChange={(newValue, action) => onFilteredChangeCustom(newValue as any, action as any, 'instance')}
              />
              <Select
                className="d-inline-block mx-4 w-196"
                placeholder={tradTableLicenseState}
                options={selectOption('licenseState')}
                isMulti
                isClearable={false}
                onChange={(newValue, action) => onFilteredChangeCustom(newValue, action, 'state')}
              />
            </DataTable>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default LicenseTable;
