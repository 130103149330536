import React, { FC, useContext } from 'react';
import AccountForm from '../../Form/AccountForm';
import { CustomModal } from '@stereograph/storybook/dist/components/CustomModal/CustomModal';
import { Account } from '../../../interface/account';
import { accountApi } from '../../../api/accountApi';
import { ToasterContext } from '../../../pages/App/App';
import { tradModifAccount, tradToasterAccount } from './AccountKeyTranslation';

interface AccountFormProps {
    account: Account;
    setAccount: Function;
    show: boolean;
    setShow: Function;
    refreshData: boolean;
    setRefreshData: Function;
}

const UpdateAccountModal: FC<AccountFormProps> = (props) => {
    const { tradToasterAccountSuccess, tradToasterAccountSuccessUpdate } = tradToasterAccount();
    const { tradModifAccountValidation, tradModifAccountTitle, tradModifAccountCancel } = tradModifAccount();

    const { show, setAccount, setShow, account, refreshData, setRefreshData } = props;
    const { pushToast } = useContext(ToasterContext);

    const onNameChanged = (event) => {
        const newAccount = Object.assign({}, account);
        newAccount.name = event.target.value;
        setAccount(newAccount);
    }

    const onContactPrincipalChanged = (event) => {
        const newAccount = Object.assign({}, account);
        newAccount.principalContact = event.target.value;
        setAccount(newAccount);
    }

    const onEmailPrincipalChanged = (event) => {
        const newAccount = Object.assign({}, account);
        newAccount.principalEmail = event.target.value;
        setAccount(newAccount);
    }

    const onSubmiteForm = async (account) => {
        const result = await accountApi.update(account);
        if (result?.status === 204) {
            pushToast({
                type: 'success',
                title: tradToasterAccountSuccess,
                message: tradToasterAccountSuccessUpdate,
                autoHide: true
            });
            setRefreshData(!refreshData);
        }
        setShow(false);
        setAccount({
            id: 0,
            name: '',
            principalContact: '',
            principalEmail: ''
        });
    }
    return (
        <CustomModal show={show} setShow={setShow}
            onAction={() => { onSubmiteForm(account) }} actionName={tradModifAccountValidation}
            title={tradModifAccountTitle} type="primary" cancelButton={tradModifAccountCancel}>
            <AccountForm
                account={account}
                onChangeActionName={(event) => { onNameChanged(event) }}
                onContactPrincipalChanged={(event) => { onContactPrincipalChanged(event) }}
                onEmailPrincipalChanged={(event) => { onEmailPrincipalChanged(event) }}
            />
        </CustomModal>
    )
}
export default UpdateAccountModal;
