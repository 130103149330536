import axios from '../middleware/requestInterceptor';

export const stereoApplicationApi = {
    create: async (application: any) => {
            const result = await axios.post(`/instances/${application.instanceId}/applications`, {
                instanceId: application.instanceId,
                url: application.url,
                type: application.type
            })
            return result;
    },
    update: async (application: any) => {
            const result = await axios.put(`/instances/${application.instanceId}/applications/${application.stereoApplicationId}`,
                {
                    stereoApplicationId: application.stereoApplicationId,
                    instanceId: application.instanceId,
                    url: application.url
                })
            return result;
    },
    delete: async (application: any) => {
            const result = await axios.delete(`/instances/${application.instanceId}/applications/${application.instanceId}`,
                {
                    data: {
                        stereoApplicationId: application.stereoApplicationId,
                        instanceId: application.instanceId
                    }
                })
            return result;
    }
}
