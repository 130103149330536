/* eslint-disable no-unused-vars */
import { t } from 'i18next';
import { translatedText } from '../../i18n/config';

export function tradDelegationForm() {
    const tradDelegationFormName = translatedText('delegation.name', t);
    const tradDelegationFormId = translatedText('delegation.clientId', t);
    const tradDelegationFormSecret = translatedText('delegation.clientSecret', t);
    const tradDelegationFormEndPoint = translatedText('delegation.discoveryEndpoint', t);
    const tradDelegationFormStartegy = translatedText('delegation.strategy', t);
    const tradDelegationFormAutoRedirect = translatedText('delegation.configuration.autoRedirect', t);
    const tradDelegationFormAutoConfirmEmail = translatedText('delegation.configuration.autoConfirmEmail', t);
    const tradDelegationFormResetPassword = translatedText('delegation.configuration.forceResetPassword', t);
    const tradDelegationFormScheme = translatedText('delegation.scheme', t);
    const tradDelegationFormShowMore = translatedText('show_more', t);
    const tradDelegationFormPlaceHolder = translatedText('delegation.name', t);
    return { tradDelegationFormName, tradDelegationFormId, tradDelegationFormSecret, tradDelegationFormEndPoint, tradDelegationFormStartegy, tradDelegationFormAutoRedirect, tradDelegationFormAutoConfirmEmail, tradDelegationFormResetPassword, tradDelegationFormScheme, tradDelegationFormShowMore, tradDelegationFormPlaceHolder };
}

export function tradDelegationShowMore() {
    const tradDelegationShowMoreAuthority = translatedText('delegation.authority', t);
    const tradDelegationShowMoreAuthorization = translatedText('delegation.authorizationEndpoint', t);
    const tradDelegationShowMoreToken = translatedText('delegation.tokenEndpoint', t);
    const tradDelegationShowMoreUserInfo = translatedText('delegation.UserInfoEndpoint', t);
    const tradDelegationShowMoreShowLess = translatedText('show_less', t);
    return { tradDelegationShowMoreAuthority, tradDelegationShowMoreAuthorization, tradDelegationShowMoreToken, tradDelegationShowMoreUserInfo, tradDelegationShowMoreShowLess };
}

export function tradDelegationKeys() {
    const tradDelegationKeysDefault = translatedText('delegation.default', t);
    const tradDelegationKeysMicrosoft = translatedText('delegation.microsoft', t);
    const tradDelegationCognito = translatedText('delegation.cognito', t);
    const tradDelegationKeysGoogle = translatedText('delegation.google', t);
    return { tradDelegationKeysDefault, tradDelegationKeysMicrosoft, tradDelegationCognito, tradDelegationKeysGoogle };
}

export function tradAccountForm() {
    const tradAccountFormName = translatedText('account_name', t);
    const tradAccountFormPlaceholder = translatedText('account.placeholder.name', t);
    const tradAccountFormPrincipalContact = translatedText('account.primary_contact', t);
    const tradAccountFormPrimaryContact = translatedText('account.placeholder.principal_contact', t);
    const tradAccountFormEmail = translatedText('account.email_contact', t);
    const tradAccountFormPlaceholderEmail = translatedText('account.placeholder.email', t);
    return { tradAccountFormName, tradAccountFormPlaceholder, tradAccountFormPrincipalContact, tradAccountFormPrimaryContact, tradAccountFormEmail, tradAccountFormPlaceholderEmail };
}

export function tradLicenseForm() {
    const tradLicenseFormAccount = translatedText('license.form.choose_account', t);
    const tradLicenseFormInstances = translatedText('license.form.choose_instance', t);
    const tradLicenseFormType = translatedText('license.form.choose_licence_type', t);
    const tradLicenseFormDate = translatedText('license.form.choose_limit_date', t);
    const tradLicenseFormUsers = translatedText('license.form.choose_limit_users', t);
    const tradLicenseFormProjects = translatedText('license.form.choose_limit_projects', t);
    return { tradLicenseFormAccount, tradLicenseFormInstances, tradLicenseFormType, tradLicenseFormDate, tradLicenseFormUsers, tradLicenseFormProjects };
}
