/* eslint-disable no-unused-vars */
import { t } from 'i18next';
import { translatedText } from '../../i18n/config';

export function tradDashboard() {
    const tradDashboardAccount = translatedText('dashboard.accounts', t);
    const tradDashboardMenu = translatedText('menu.account', t);
    const tradDashboardInstance = translatedText('instances.instances', t);
    const tradDashboardUsers = translatedText('dashboard.users', t);
    return { tradDashboardAccount, tradDashboardMenu, tradDashboardInstance, tradDashboardUsers };
}
