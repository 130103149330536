import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { getToken } from '../utils/authentication';

const customAxios: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  headers: { }
});

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  config.headers = {
    Authorization: `Bearer ${getToken()}`
  };
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  console.error(JSON.stringify(error));
  return Promise.reject(error);
};

customAxios.interceptors.request.use(onRequest, onRequestError);

export default customAxios;
