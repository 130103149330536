import { Users } from '../interface/users';
import axios from '../middleware/requestInterceptor';

export const userApi = {
  async getAll(instanceId: number) {
    const result = await axios.get(`/instances/${instanceId}/users`);
    return result.data;
  },
  async createUser(instanceId: number, data: Users) {
    if (data) {
      const result = await axios.post(`/instances/${instanceId}/users`, { id: data?.id, name: data?.name, email: data?.email, roles: data?.roles });
      return result;
    }
  },
  async delete(instanceId: number, userId: string) {
    const result = await axios.delete(`/instances/${instanceId}/users/${userId}`);
    return result;
  },
  async update(instanceId: number, user:Users) {
    const result = await axios.put(`/instances/${instanceId}/users/${user.id}`, {
      id: user.id,
      name: user.name,
      email: user.email,
      roles: user.roles
    });
    return result;
  },
  async resend(instanceId: number, userId: string) {
    return await axios.put(`/instances/${instanceId}/users/${userId}/resend`);
  },
  async resetpassword(instanceId: number, userId: string) {
    return await axios.put(`/instances/${instanceId}/users/${userId}/resetpassword`);
  }
}
