import { t } from 'i18next';
import { translatedText } from '../../../i18n/config';

export function tradHeader() {
    const tradHeaderAccount = translatedText('instances.account', t);
    const tradHeaderInstance = translatedText('instances.instances', t);
    const tradHeaderType = translatedText('instances.type', t);
    const tradHeaderEnvironment = translatedText('instances.environment', t);
    const tradHeaderState = translatedText('instances.instance_state', t);
    const tradHeaderCreation = translatedText('instances.createAt', t);
    const tradHeaderInstanceToken = translatedText('instances.instanceToken', t);
    const tradHeaderCopyToken = translatedText('instances.copyToken', t);
    const tradHeaderLabel = translatedText('actions.label', t);
    return { tradHeaderAccount, tradHeaderInstance, tradHeaderType, tradHeaderEnvironment, tradHeaderState, tradHeaderCreation, tradHeaderInstanceToken, tradHeaderCopyToken, tradHeaderLabel };
}

export function tradLabel() {
    const tradLabelSeeDetails = translatedText('instance.see_details', t);
    const tradLabelUpdate = translatedText('update', t);
    const tradLabelDelete = translatedText('delete', t);
    return { tradLabelSeeDetails, tradLabelUpdate, tradLabelDelete };
}

export function tradTable() {
    const tradTableInstance = translatedText('instances.instances', t);
    const tradTableCsv = translatedText('instances.download_csv_file', t);
    const tradTableAddInstance = translatedText('instances.add_new_instances', t);
    const tradTableSortPage = translatedText('sort_per_page', t);
    const tradTabledate = translatedText('date.format', t);
    const tradTableFilter = translatedText('instances.search_by', t);
    return { tradTableInstance, tradTableCsv, tradTableAddInstance, tradTableSortPage, tradTabledate, tradTableFilter };
}

export function tradPlaceHolder() {
    const tradPlaceHolderInstanceToken = translatedText('placeholder.instance_token', t);
    const tradPlaceHolderAccount = translatedText('placeholder.accounts', t);
    const tradPlaceHolderInstance = translatedText('placeholder.instance', t);
    const tradPlaceHolderHebType = translatedText('placeholder.heb_type', t);
    const tradPlaceHolderState = translatedText('placeholder.state', t);
    return { tradPlaceHolderInstanceToken, tradPlaceHolderAccount, tradPlaceHolderInstance, tradPlaceHolderHebType, tradPlaceHolderState };
}
