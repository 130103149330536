import { faNetworkWired, faHome, faIndustry, faKey } from '@fortawesome/free-solid-svg-icons';
import { t } from 'i18next';

interface MenuItem {
  name: string,
  path: string,
  icon: any
}

const routes = [{
  name: t('menu.title'),
  items: [
    {
      name: t('menu.dashboard'),
      path: '/dashboard',
      icon: faHome
    },
    {
      name: t('menu.account'),
      path: '/account',
      icon: faNetworkWired
    },
    {
      name: t('menu.instances'),
      path: '/instances',
      icon: faIndustry
    },
    {
      name: t('menu.license'),
      path: '/license',
      icon: faKey
    }
  ]
}]

export type { MenuItem };

export default routes;
