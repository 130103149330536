import { t } from 'i18next';
import { translatedText } from '../../../i18n/config';

export function tradToasterAccount() {
    const tradToasterAccountSuccess = translatedText('messages.success', t);
    const tradToasterAccountSuccessCreate = translatedText('account.create.success', t);
    const tradToasterAccountSuccessDelete = translatedText('account.delete.success', t);
    const tradToasterAccountSuccessUpdate = translatedText('account.update.success', t);
    return { tradToasterAccountSuccess, tradToasterAccountSuccessCreate, tradToasterAccountSuccessDelete, tradToasterAccountSuccessUpdate };
}

export function tradCreationAccount() {
    const tradCreationAccountValidation = translatedText('add_account.validation', t);
    const tradCreationAccountTitle = translatedText('add_account.title_modal', t);
    const tradCreationAccountCancel = translatedText('add_account.cancel_button', t);
    return { tradCreationAccountValidation, tradCreationAccountTitle, tradCreationAccountCancel };
}

export function tradDeleteAccount() {
    const tradDeleteAccountCancel = translatedText('cancel', t);
    const tradDeleteAccountTitle = translatedText('account.delete.confirm', t);
    const tradDeleteAccountConfirmation = translatedText('Confirmation', t);
    const tradDeleteAccountMessage = translatedText('account.delete.confirmation_message', t);
    return { tradDeleteAccountCancel, tradDeleteAccountTitle, tradDeleteAccountConfirmation, tradDeleteAccountMessage };
}

export function tradModifAccount() {
    const tradModifAccountValidation = translatedText('update_account.validation', t);
    const tradModifAccountTitle = translatedText('update_account.title_modal', t);
    const tradModifAccountCancel = translatedText('update_account.cancel_button', t);
    return { tradModifAccountValidation, tradModifAccountTitle, tradModifAccountCancel };
}
