import React, { FC } from 'react';
import { Modal, Button } from 'react-bootstrap';

interface CustomModalProps {
  show: boolean;
  setShow: Function;
  onAction: Function;
  actionName: string;
  onClose?: Function;
  title: string;
  type: 'success' | 'warning' | 'danger' | 'info' | 'primary';
  size?: 'sm' | 'lg' | 'xl';
  disabled?: boolean;
  cancelButton?: string;
  onActionCancel?: Function;
}

const CustomModal: FC<CustomModalProps> = (props) => {
  const { show, setShow, onAction, actionName, onClose, children, title, size, type, disabled, cancelButton, onActionCancel } = props;

  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
    setShow(false);
  }

  return (
    <>
      <Modal show={show} onHide={onActionCancel ? () => { onActionCancel(); handleClose(); } : handleClose} size={size}>
        <Modal.Header closeButton>
          <Modal.Title >{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {children}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onActionCancel ? () => onActionCancel() : handleClose}>{cancelButton}</Button>
          <Button variant={type} onClick={() => onAction()} disabled={!!disabled}>{actionName}</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CustomModal;
