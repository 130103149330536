import axios from '../middleware/requestInterceptor';
import { t } from 'i18next';
import { Account } from '../interface/account';

export const accountApi = {
  async getAll() {
    const result = await axios.get('/accounts');
    const arrayAccount: Array<Account> = [];
    result.data.map((account: Account) => {
      const date: Date = new Date(account.creationDate);
      return arrayAccount.push({ ...account, creationLocaleDate: date.toLocaleString(t('date.format')) });
    });
    return arrayAccount;
  },
  async create(account) {
    const result = await axios.post('/accounts', {
      name: account.name,
      contactPrincipal: account.principalContact,
      emailPrincipal: account.principalEmail
    });
    return result;
  },
  async update(account) {
    const result = await axios.put(`/accounts/${account.id}`, {
      accountId: account.id,
      name: account.name,
      contactPrincipal: account.principalContact,
      emailPrincipal: account.principalEmail
    });
    return result;
  },
  async delete(account) {
    const result = await axios.delete(`/accounts/${account.id}`);
    return result;
  },
  async exportAccounts() {
    try {
      const response = await axios.get('/accounts/export', { responseType: 'blob' });
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Corporate_List_Account.xlsx';
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting all accounts: ', error);
    }
  }
};
