import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index_tailwind.css';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './pages/App/App';
import dotenv from 'dotenv';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
dotenv.config();

  ReactDOM.render(
    <BrowserRouter >
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>,
    </BrowserRouter>,
    document.getElementById('root'))
