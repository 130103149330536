/* eslint-disable no-unused-vars */
import { t } from 'i18next';
import { translatedText } from '../../../i18n/config';

export function tradToaster() {
    const tradToasterSuccess = translatedText('messages.success', t);
    const tradToasterSuccessMessage = translatedText('license.create.success', t);
    const tradToasterSuccessDelete = translatedText('license.delete.success', t);
    return { tradToasterSuccess, tradToasterSuccessMessage, tradToasterSuccessDelete };
}

export function tradCreation() {
    const tradCreationValidation = translatedText('add_license.validation', t);
    const tradCreationTitle = translatedText('add_license.title_modal', t);
    const tradCreationCancel = translatedText('add_license.cancel_button', t);
    return { tradCreationValidation, tradCreationTitle, tradCreationCancel };
}

export function tradDelete() {
    const tradDeleteCancel = translatedText('cancel', t);
    const tradDeleteConfirm = translatedText('license.delete.confirm', t);
    const tradDeleteTitle = translatedText('Confirmation', t);
    const tradDeleteDelete = translatedText('license.delete.confirmation_message', t);
    return { tradDeleteCancel, tradDeleteConfirm, tradDeleteTitle, tradDeleteDelete };
}

export function tradHistory() {
    const tradHistoryTitle = translatedText('license.history.title', t);
    return { tradHistoryTitle };
}
