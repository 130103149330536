import { ClientIdentity } from '../interface/ClientIdentity/clientIdentity';
import axios from '../middleware/requestInterceptor';

export const clientIdentityApi = {
  getAll: async (instanceId: number) => {
    const result = await axios.get(`/instances/${instanceId}/clients`);
    return result.data
  },
  post: async (instanceId: number, query: ClientIdentity) => {
    return await axios.post(`/instances/${instanceId}/clients`, query);
  },
  delete: async (instanceId: number, configurationClientId: number) => {
    return await axios.delete(`/instances/${instanceId}/clients/${configurationClientId}`);
  },
  patch: async (instanceId: number, configurationClientId: number, query: ClientIdentity) => {
    return await axios.put(`/instances/${instanceId}/clients/${configurationClientId}`, query);
  },
  getOpenIdConfiguration: async () => {
    const result = await axios.get(`${process.env.REACT_APP_IDENTITY_URL}/.well-known/openid-configuration`);
    return result.data
  }
}
