import React from 'react'
import { tradError404 } from '../ErrorKeyTranslation';
import CorporateManagementLogo from '../../../pages/Home/CorporateManagementLogo.svg'
import { Link } from 'react-router-dom';

const Error404 = () => {
  const { tradError404Title, tradError404Description, tradHomeImageDesc } = tradError404();
  return (
    <>
      <Link to="/"><img style={{ height: '50px' }} src="Logo-stereograph.png"></img></Link>

      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className=" st-flex st-justify-center">
            <div className="text-center">
              <img style={{ width: 300, paddingLeft: 10 }} src={CorporateManagementLogo} alt={tradHomeImageDesc} />
              <h1 className="text-error mt-4">404</h1>
              <h4 className="text-uppercase text-primary mt-3">{tradError404Title}</h4>
              <p className="text-muted mt-3">{tradError404Description}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Error404;
