import React, { FC, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { tradHome } from './HomeKeyTranslation';
import CorporateManagementLogo from '../Home/CorporateManagementLogo.svg'

import { authenticationFlow } from '../../utils/authentication';

const Home: FC = () => {
  const { tradHomelogin, tradHomeImageDesc } = tradHome();
  useEffect(() => {
    document.cookie = 'Stereograph.Identity.IdToken=; Expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;';
    document.cookie = 'Stereograph.Identity.Token=; Expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;';
  }, []);

  return (
    <>
      <Link to="/"><img style={{ height: '50px' }} src="Logo-stereograph.png"></img></Link>
      <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: 'calc(80vh - 50px)' }} >
        <img style={{ width: 400 }} src={CorporateManagementLogo} alt={tradHomeImageDesc}/>
        <Button onClick={() => { authenticationFlow('/account') }} className="btn btn-primary align-self-center st-m-8"> {tradHomelogin}</Button>
      </div>
    </>

  )
}

export default Home;
