import React, { ChangeEvent, FC, useContext, useEffect, useMemo, useState } from 'react';
import { Button, ButtonGroup, Card, Col, Container, Dropdown, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import InstanceCard from '../../components/Cards/InstanceCard';
import LicenseCard from '../../components/Cards/LicenseCard';
import { instanceApi } from '../../api/instanceApi';
import { useParams } from 'react-router-dom';
import { Instance } from '../../models/instance';
import { DataTable, GenericFilter } from '@stereograph/storybook';
import { perPageOptions } from '../../utils/files-utils';
import { userApi } from '../../api/userApi';
import { Role, Users } from '../../interface/users';
import UserEditionModal from '../../components/Modals/Users/UserEditionModal';
import { CellProps } from 'react-table';
import DeleteUserModal from '../../components/Modals/Users/DeleteUserModal';
import DelegationEditionModal from '../../components/Modals/Delegation/DelegationEditionModal';
import { Delegation } from '../../interface/delegation';
import { delegationAPi } from '../../api/delegationApi';
import DeleteDelegationModal from '../../components/Modals/Delegation/DeleteDelegationModal';
import UserUpdateModal from '../../components/Modals/Users/UserUpdateModal';
import ClientCard from '../../components/Cards/ClientIdentityCard';
import { ToasterContext } from '../App/App';
import { rolesBadgeStyle } from '../../components/Tables/BadgesStyle';
import { tradInstanceDetails, tradInstanceDetailsTable, tradInstancedetailsToaster } from './InstanceDetailsKeyTranslation';

const ActionType = {
  CREATE: 1,
  UPDATE: 2,
  DELETE: 3
};

const InstanceDetails: FC = () => {
  const { tradInstancedetailsToasterSuccess, tradInstancedetailsToasterResendSuccess, tradInstancedetailsToasterResetSuccess } = tradInstancedetailsToaster();
  const { tradInstanceDetailsName, tradInstanceDetailsEmail, tradInstanceDetailsRole, tradInstanceDetailsLabel, tradInstanceDetailsUpdate, tradInstanceDetailsDelete, tradInstanceDetailsResend, tradInstanceDetailsReset } = tradInstanceDetails();
  const { tradInstanceDetailsTableTitle, tradInstanceDetailsTableIdentityDelegation, tradInstanceDetailsTableAddUser, tradInstanceDetailsTableFormat, tradInstanceDetailsTableSortPage, tradInstanceDetailsTableSearch } = tradInstanceDetailsTable();
  const { instanceId } = useParams();
  const [reloadData, setReloaData] = useState<boolean>(false);
  const [currentInstance, setCurrentInstance] = useState<Instance>(new Instance());
  const [originalInstance, setOriginalInstance] = useState<Instance>(currentInstance);
  const [users, setUsers] = useState<Array<Users>>([]);
  const [currentUser, setCurrentUser] = useState<Users>({
    id: '',
    name: '',
    email: '',
    roles: []
  });
  const [showUserModal, setShowUserModal] = useState<boolean>(false);
  const [actionType, setActionType] = useState<any>(ActionType.CREATE);
  const [showDelegationModal, setShowDelegationModal] = useState<boolean>(false);
  const [delegations, setDelegations] = useState<Array<Delegation>>([]);
  const [delegationActionType, setDelegationActionType] = useState<any>(ActionType.CREATE);
  const [delegation, setDelegation] = useState<Delegation>({
    instanceId: instanceId ? +instanceId : 0,
    instanceToken: originalInstance.instanceToken,
    name: '',
    authorizationEndpoint: '',
    authority: '',
    clientSecret: '',
    clientId: '',
    discoveryEndpoint: '',
    tokenEndpoint: '',
    scheme: '',
    userInfoEndpoint: '',
    roleStrategy: 0,
    configuration: {
      autoRedirect: false,
      autoConfirmEmail: false,
      forceResetPassword: false
    }
  });
  const [application, setApplication] = useState(currentInstance.applications);
  const [filterValue, setFilterValue] = useState('');
  const [filteredUsers, setFilteredUsers] = useState<Users[]>([]);
  const { pushToast } = useContext(ToasterContext);

  useEffect(() => {
    const fetchInstance = async () => {
      const result = await instanceApi.getInstanceById(instanceId);
      setCurrentInstance(result);
      setOriginalInstance(new Instance(result));
      setApplication(result.applications);
    };
    fetchInstance();
  }, [reloadData, instanceId]);

  useEffect(() => {
    const fetchUsers = async () => {
      if (originalInstance.instanceId) {
        const result = await userApi.getAll(originalInstance.instanceId);
        setUsers(result);
      }
    };
    fetchUsers();
  }, [originalInstance, reloadData]);

  useEffect(() => {
    const fetchDelegations = async () => {
      if (originalInstance.instanceId) {
        const result = await delegationAPi.getAll(originalInstance.instanceId);
        setDelegations(result.data);
      }
    };
    fetchDelegations();
  }, [originalInstance, reloadData]);

  const addUser = async () => {
    setActionType(ActionType.CREATE);
    setShowUserModal(true);
  };

  const deleteUser = async (user: Users) => {
    setActionType(ActionType.DELETE);
    setCurrentUser(user);
    setShowUserModal(true);
  };

  const resendUser = async (user: Users) => {
    const result = await userApi.resend(originalInstance.instanceId, user.id);
    if (result.status === 204) {
      pushToast({
        type: 'success',
        title: tradInstancedetailsToasterSuccess,
        message: tradInstancedetailsToasterResendSuccess,
        autoHide: true
      });
    }
  };

  const resetPassword = async (user: Users) => {
    const result = await userApi.resetpassword(originalInstance.instanceId, user.id);
    if (result.status === 204) {
      pushToast({
        type: 'success',
        title: tradInstancedetailsToasterSuccess,
        message: tradInstancedetailsToasterResetSuccess,
        autoHide: true
      });
    }
  };

  const updateUser = (user) => {
    if (user) {
      const selectedUserRole: Array<any> = [];

      user.roles.map((role) => {
        const obj = {
          label: role.name,
          value: role.id
        };
        return selectedUserRole.push(obj);
      });
      setActionType(ActionType.UPDATE);
      setCurrentUser({
        id: user.id,
        name: user.name,
        email: user.email,
        roles: selectedUserRole
      });
      setShowUserModal(true);
    }
  };

  const addDelegation = () => {
    setDelegation({
      instanceId: instanceId ? +instanceId : 0,
      instanceToken: originalInstance.instanceToken,
      name: '',
      authorizationEndpoint: '',
      authority: '',
      clientSecret: '',
      clientId: '',
      discoveryEndpoint: '',
      tokenEndpoint: '',
      scheme: '',
      userInfoEndpoint: '',
      roleStrategy: 0,
      configuration: {
        autoRedirect: false,
        autoConfirmEmail: false,
        forceResetPassword: false
      }
    });
    setDelegationActionType(ActionType.CREATE);
    setShowDelegationModal(true);
  };
  const updateDelegation = (delegation: Delegation) => {
    delegation.instanceId = instanceId ? +instanceId : 0;
    setDelegation(delegation);
    setDelegationActionType(ActionType.UPDATE);
    setShowDelegationModal(true);
  };
  const DeleteDelegation = (delegation: Delegation) => {
    setDelegation(delegation);
    setDelegationActionType(ActionType.DELETE);
    setShowDelegationModal(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: tradInstanceDetailsName,
        accessor: 'name',
        Cell: ({ cell }: CellProps<any>) => (
          <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>{cell.row.original.name}</Tooltip>}>
            <span className="text-truncate" style={{ maxWidth: '400px', display: 'inline-block', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {cell.row.original.name}
            </span>
          </OverlayTrigger>
        )
      },
      {
        Header: tradInstanceDetailsEmail,
        accessor: 'email',
        Cell: ({ cell }: CellProps<any>) => (
          <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>{cell.row.original.email}</Tooltip>}>
            <span className="text-truncate" style={{ maxWidth: '400px', display: 'inline-block', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {cell.row.original.email}
            </span>
          </OverlayTrigger>
        )
      },
      {
        Header: tradInstanceDetailsRole,
        accessor: (users) => {
          const output: Array<string> = [];
          users.roles.map((role: Role) => {
            return output.push(role.name);
          });
          return output.join(', ');
        },
        Cell: ({ cell }: CellProps<any>) => (
          <>
            {cell.row.original.roles.map((role, index) => (
              <React.Fragment key={index + 1}>
                <span className={rolesBadgeStyle} key={role.id}>
                  {role.name}
                  </span>
                <React.Fragment key={index}>&nbsp;</React.Fragment>
              </React.Fragment>
            ))}
          </>
        )
      },
      {
        Header: tradInstanceDetailsLabel,
        accessor: '',
        disableSortBy: true,
        Cell: ({ cell }: CellProps<any>) => (
          <>
            <Dropdown>
              <Dropdown.Toggle variant="primary" size="sm">
                {tradInstanceDetailsLabel}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => updateUser(cell.row.original)}>{tradInstanceDetailsUpdate}</Dropdown.Item>
                <Dropdown.Item onClick={() => deleteUser(cell.row.original)}>{tradInstanceDetailsDelete}</Dropdown.Item>
                <Dropdown.Item onClick={() => resendUser(cell.row.original)}>{tradInstanceDetailsResend}</Dropdown.Item>
                <Dropdown.Item onClick={() => resetPassword(cell.row.original)}>{tradInstanceDetailsReset}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        )
      }
    ],
    [originalInstance]
  );

  const handleFilter = (event: ChangeEvent) => {
    const inputValue = (event.target as HTMLInputElement).value;

    setFilterValue(inputValue);
    const filteredUsers = users.filter((user) =>
      user.email.toLowerCase().includes(inputValue.toLowerCase()) || user.name.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredUsers(filteredUsers);
  };

  return (
    <>
      {actionType === ActionType.CREATE && (
        <UserEditionModal
          originalInstance={originalInstance}
          reloadData={reloadData}
          show={showUserModal}
          setShow={setShowUserModal}
          setReloadData={setReloaData}
        />
      )}
      {actionType === ActionType.UPDATE && (
        <UserUpdateModal
          originalInstance={originalInstance}
          reloadData={reloadData}
          show={showUserModal}
          setShow={setShowUserModal}
          setReloadData={setReloaData}
          currentUser={currentUser}
        />
      )}
      {actionType === ActionType.DELETE && (
        <DeleteUserModal
          show={showUserModal}
          setShow={setShowUserModal}
          refreshUser={setReloaData}
          currentInstance={currentInstance}
          user={currentUser}
        />
      )}
      {delegationActionType !== ActionType.DELETE && (
        <DelegationEditionModal
          actionType={delegationActionType}
          delegation={delegation}
          setDelegation={setDelegation}
          instanceId={instanceId ? +instanceId : 0}
          show={showDelegationModal}
          setShow={setShowDelegationModal}
          refreshDelegations={reloadData}
          setRefreshDelegations={setReloaData}></DelegationEditionModal>
      )}
      {delegationActionType === ActionType.DELETE && (
        <DeleteDelegationModal
          show={showDelegationModal}
          setShow={setShowDelegationModal}
          delegationId={delegation.id ?? 0}
          instanceId={instanceId ? +instanceId : 0}
          refreshDelegations={reloadData}
          setRefreshDelegations={setReloaData}></DeleteDelegationModal>
      )}
      <Container>
        <Row>
          <Col lg={8} md={12} className="mb-4">
            <InstanceCard
              application={application}
              setReloadData={setReloaData}
              reloadData={reloadData}
              originalInstance={originalInstance}
              currentInstance={currentInstance}
              setCurrentInstance={setCurrentInstance}
            />
          </Col>
          <Col>
            <LicenseCard originalInstance={originalInstance} reloadData={reloadData} setReloaData={setReloaData} />
          </Col>
          {users && (
            <Col lg={12} className="mb-4">
              <Card className="card-table mt-4">
                <Card.Header className="d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <h4 className="card-heading">{tradInstanceDetailsTableTitle}</h4>
                    {delegations.map((delegation: Delegation) => {
                      return (
                        <ButtonGroup size="sm" key={delegation.id} className="ms-1 h-75">
                          <Button variant="primary" onClick={() => updateDelegation(delegation)}>
                            {' '}
                            {delegation.name}
                          </Button>
                          <Button onClick={() => DeleteDelegation(delegation)}>X</Button>
                        </ButtonGroup>
                      );
                    })}
                  </div>
                  <div>
                    <Button className="mx-2" variant="primary" onClick={() => addDelegation()}>
                      {tradInstanceDetailsTableIdentityDelegation}
                    </Button>
                    <Button variant="primary" onClick={() => addUser()}>
                      {tradInstanceDetailsTableAddUser}
                    </Button>
                  </div>
                </Card.Header>
                <Card.Body>
                  <DataTable
                    columns={columns}
                    items={filterValue ? filteredUsers : users}
                    perPageOptions={perPageOptions}
                    lang={tradInstanceDetailsTableFormat}
                    labelPage={tradInstanceDetailsTableSortPage}
                    genericFilterValue={filterValue}
                  >
                    <GenericFilter handleChange={handleFilter} placeholder={tradInstanceDetailsTableSearch} />
                  </DataTable>
                </Card.Body>
              </Card>
            </Col>
          )}
          {currentInstance.instanceId !== 0 && currentInstance.instanceToken !== '' && (
            <Col className="mb-4" lg={12}>
              <ClientCard instanceToken={currentInstance.instanceToken} instanceId={currentInstance.instanceId} />
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default InstanceDetails;
