import React, { FC } from 'react';
import { Modal } from 'react-bootstrap';
import HistoryLicenseTable from '../../Tables/LicenseTable/HistoryLicenseTable';
import { tradHistory } from './LicenseKeyTranslation';

interface HistoryLicenseModalProps {
    instanceId: number;
    show: boolean;
    setShow: Function;
}

const HistoryLicenseModal: FC<HistoryLicenseModalProps> = (props) => {
    const { tradHistoryTitle } = tradHistory();
    const { show, setShow, instanceId } = props;
    return (
        <Modal size='xl'
            show={show}
            onHide={() => setShow(false)}
        >
            <Modal.Header className='p-4 d-flex justify-content-between align-items-center'>
                <Modal.Title>
                    {tradHistoryTitle}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <HistoryLicenseTable instanceId={instanceId}>
                </HistoryLicenseTable>
            </Modal.Body>
        </Modal>
    );
}

export default HistoryLicenseModal;
