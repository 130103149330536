import React from 'react';
import { Form } from 'react-bootstrap';
import { t } from 'i18next';

function AddUrlApplication(name: string, onChangeUrl) {
    return (
        <Form.Group>
            <div className="fs-4">{name}</div>
            <Form.Label className="text-uppercase">{t('instance.url')}</Form.Label>
            <Form.Control type="text" placeholder="https://...." onChange={(e) => onChangeUrl(e)} />
        </Form.Group>
    );
}

export default AddUrlApplication
